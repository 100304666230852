import { useCallback, useEffect, useMemo } from 'react';

import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  ErrorType,
  GENERIC_ERROR,
  PAYMENT_CURRENCY_UNSUPPORTED,
  PAYMENT_DISABLED,
  PAYMENT_TEMPORARY_ERROR,
} from '../../entities';
import { UIModes } from '../../entities/api';
import useRedirectURI from '../../hooks/useRedirectURI';
import { decodeToken, getProductFlow, getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { commonErrorCTAButtonAction } from '../../utils/error_page';
import { translateErrorDetails } from '../../utils/translate';
import { MessageState, SCREEN_TYPE } from '../common/utils';
import { ReferenceIdBox, GetMessageScreenJSX } from '../Onboarding/MessageScreen';

// Page is rendered on `/error`. Typical case we see this used is when you visit a payment link and get an error
// Expectations:
// 1. There is an error_code parameter in the URL.
// 2. There is no token on the URL parameters
export default function GenericErrorScreen(): JSX.Element {
  const { t } = useTranslation();

  const { search } = useLocation();
  const searchParamsV2 = new URLSearchParams(search);
  const theme = createTheme(getTheme());

  let error: ErrorType;

  switch (searchParamsV2.get('error_code')) {
    case 'PAYMENT_TEMPORARY_ERROR':
      error = PAYMENT_TEMPORARY_ERROR;
      break;
    case 'PAYMENT_DISABLED':
      error = PAYMENT_DISABLED;
      break;
    case 'PAYMENT_CURRENCY_UNSUPPORTED':
      error = PAYMENT_CURRENCY_UNSUPPORTED;
      break;
    default:
      error = GENERIC_ERROR;
      break;
  }

  const { title, header, body } = {
    title: 'Error',
    header: t(error.message),
    body: t(error.details),
  };

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.ERROR, {
      errorCode: error.type,
      errorMessage: t(error.message, { lng: 'en' }),
      errorDetails: t(error.details, { lng: 'en' }),
    });
  }, [error, t]);

  // TODO: Improve utility function to make this optional?
  // Or make title, header, body separately optional.
  const state: MessageState = {
    title: title,
    header: header,
    body: body,
    destination: undefined, // When this is undefined, the back button is not shown, which is what we want for Generic Error.
  };

  const displayRefId = () => {
    const customerAppId = searchParamsV2.get('customer_app_id');
    if (customerAppId) {
      return <ReferenceIdBox institutionId={'finverse'} refernceId={customerAppId} message="customerAppIdMessage" />;
    }
    return undefined;
  };

  return GetMessageScreenJSX({
    theme: theme,
    uiMode: UIModes.standalone,
    goBack: () => undefined, // noop
    state: state, //
    title: title,
    header: header,
    body: body,
    institutionId: 'finverse', // No institution behavior
    type: SCREEN_TYPE.FVERROR,
    displayReferenceId: displayRefId,
    onClickNextStep: () => undefined, // noop
  });
}

/**
 * Page is rendered on `/error/api`. Typical case we see this used is as a redirect after a FE API request fails
 *
 * Expectations:
 * 1. There are message and details in query parameters which indicates what to display. See `utils/error_page.ts`
 *
 * Optional expectations
 * 1. There is a token in the query parameters. This helps inform on CTA button action and button text
 * 2. There is a retryUrl in the query parameters. If this is provided, then CTA button will redirect to the retry url
 * 3. There is a title in the query paramters. If this is provided, then the screen title will be this value
 */
export function ApiErrorScreen(): JSX.Element {
  const { t } = useTranslation();

  const { search } = useLocation();
  // memo-ized value because this is used as a dependency in useEffect and will change object ref value on each re-render
  const searchParamsV2 = useMemo(() => new URLSearchParams(search), [search]);
  const theme = createTheme(getTheme());
  const { redirectToURI } = useRedirectURI();

  const token = searchParamsV2.get('token') ?? '';
  const productFlow = getProductFlow(token);
  const uiMode = (searchParamsV2.get('ui_mode') as UIModes) ?? UIModes.standalone;
  const decodedToken = decodeToken(token);
  const retryUrlFromParam = searchParamsV2.get('retry_url');

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.ERROR, {
      productFlow,
      errorMessage: t(searchParamsV2.get('message') ?? 'not_provided', { lng: 'en' }),
      errorDetails: t(searchParamsV2.get('details') ?? 'not_provided', { lng: 'en' }),
    });
  }, [searchParamsV2, t, productFlow]);

  const errorTitle = t(searchParamsV2.get('title') ?? 'Error');
  const errorMessage = t(searchParamsV2.get('message') ?? '');
  // translateErrorDetails will translate details except for (Error details: "details")
  const errorDetails = translateErrorDetails(t, searchParamsV2.get('details') ?? '');

  // button text should be "try again" when we can retry, else default
  const buttonText = (() => {
    if (retryUrlFromParam !== null) {
      return 'messageScreenError';
    }

    if (typeof decodedToken.retryUrl === 'string' && decodedToken.retryUrl !== '') {
      return 'messageScreenError';
    }

    return undefined;
  })();

  const onClickNextStep = useCallback(() => {
    if (retryUrlFromParam !== null) {
      return window.location.replace(retryUrlFromParam);
    }

    return commonErrorCTAButtonAction(token, uiMode, redirectToURI);
  }, [retryUrlFromParam, token, uiMode, redirectToURI]);

  return GetMessageScreenJSX({
    theme,
    uiMode,
    goBack: () => undefined,
    state: {
      title: errorTitle,
      header: errorMessage,
      body: errorDetails,
      buttonText,
    },
    title: errorTitle,
    header: errorMessage,
    body: errorDetails,
    institutionId: 'finverse',
    type: SCREEN_TYPE.FVERROR,
    // lets leave this undefined for now, we might want to show payment_attempt_id/liid/mandate_id depending on flow
    displayReferenceId: () => undefined,
    onClickNextStep,
  });
}
