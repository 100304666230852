import { ErrorType } from './error';

export default class LinkError extends Error {
  public code: number;
  public type: string;
  public details: string;
  public retry: boolean;
  public isFatal: boolean;
  constructor(error: ErrorType) {
    super(error.message);
    this.name = this.constructor.name;
    this.code = error.code;
    this.type = error.type;
    this.message = error.message;
    this.details = error.details;
    this.retry = error.retry ?? false;
    this.isFatal = error.isFatal ?? false;
  }
}
