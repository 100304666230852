import { useCallback, useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton, YouCanCloseTheWindowNow } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { UIModes } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useRedirectURI from '../../../hooks/useRedirectURI';
import useSearchParams from '../../../hooks/useSearchParams';
import { decodeToken } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { getRecipientNameFromPaymentMethod } from '../../../utils/payment_method';
import Screen, { GenericLoadingScreen } from '../../common/Screen';

export default function AutopayConsentScreen(): JSX.Element {
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  // indicates that this screen is in "final" mode, i.e. we need to redirect user away from FvLink
  const [finalScreenMode, setFinalScreenMode] = useState(false);
  const { params, searchParamsString } = useSearchParams();
  const history = useHistory();
  const client = useAPIClient();
  const token = params.token;
  const { customizationInfo } = useCustomizations();
  const { redirectToURI } = useRedirectURI();
  const uiMode = params.uiMode || UIModes.standalone;

  const decodedToken = decodeToken(token);
  const enrollmentPrefillValue = decodedToken.enrollmentScreenPrefill;

  const [userAutopaySelection, setUserAutopaySelection] = useState(enrollmentPrefillValue === 'YES' ? 'true' : 'false');

  const getPageData = useCallback(async () => {
    try {
      const paymentMethod = await client.getPaymentMethod(token);

      const recipientName =
        paymentMethod !== undefined
          ? getRecipientNameFromPaymentMethod(paymentMethod)
          : customizationInfo.displayName ?? '';

      setRecipientName(recipientName);
      setPageLoading(false);
    } catch (err) {
      history.push(getApiErrorPath(err as any, searchParamsString));
    }
  }, [client, token, history, customizationInfo.displayName, searchParamsString]);

  // on mount, we need to get payment user info from api endpoint and mark loading as done
  useEffect(() => {
    setPageLoading(true);
    getPageData();
  }, [getPageData]);

  const submitAutopayConsent = useCallback(
    async (consentVal: string) => {
      const consentValBool = consentVal.toLowerCase() === 'true';
      try {
        await client.setAutopayConsent(token, {
          autopay_consent: consentValBool,
        });
        setFinalScreenMode(true);
      } catch (err) {
        return history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [client, history, searchParamsString, token],
  );

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.AUTOPAY_ENROLLMENT, {
      ...eventProperties,
      enrollmentPrefillValue,
    });
  }, [enrollmentPrefillValue]);

  useEffect(() => {
    if (!finalScreenMode) {
      return;
    }

    // if we are in final mode, then we want to send the user to redirect destination (or iframe parent)

    if (uiMode === UIModes.redirect || uiMode === UIModes.autoRedirect) {
      setTimeout(redirectToURI, 1000);
      return;
    }

    if (uiMode === UIModes.iframe) {
      setTimeout(() => window.parent.postMessage('success', '*'), 1000);
      return;
    }
  }, [finalScreenMode, uiMode, redirectToURI]);

  if (pageLoading) {
    return <GenericLoadingScreen />;
  }

  const pageContent = (
    <RadioGroup value={userAutopaySelection} onChange={(e) => setUserAutopaySelection(e.target.value)}>
      <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
        {t('Autopay Selection')}:
      </Typography>
      <Grid container direction="column" spacing={2}>
        {(!finalScreenMode || userAutopaySelection === 'true') && (
          <Grid container item direction="row" sx={{ alignItems: 'flex-start' }}>
            <Grid item xs={2}>
              {finalScreenMode ? (
                <CheckCircleIcon color="success" fontSize="large" />
              ) : (
                <Radio sx={{ pt: 0 }} value="true" disabled={finalScreenMode} />
              )}
            </Grid>
            <Grid container item xs={10} spacing={2}>
              <Grid item>
                <Typography variant="h6">
                  <b>{t('Yes')}</b>, {t('autopay invoices sent by')}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" fontWeight="bold">
                  {recipientName}
                </Typography>
              </Grid>
              {!finalScreenMode && (
                <Grid item>
                  <Typography fontStyle="italic" variant="body2">
                    {t('enjoyHassleFreePayments')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {(!finalScreenMode || userAutopaySelection === 'false') && (
          <Grid container item direction="row" sx={{ alignItems: 'flex-start' }}>
            <Grid item xs={2}>
              {finalScreenMode ? (
                <CheckCircleIcon color="success" fontSize="large" />
              ) : (
                <Radio sx={{ pt: 0 }} value="false" disabled={finalScreenMode} />
              )}
            </Grid>
            <Grid container item xs={10} spacing={2}>
              <Grid item>
                <Typography variant="h6">
                  <b>{t('No')}</b>, {t('do not enroll into autopay')}
                </Typography>
              </Grid>
              {!finalScreenMode && (
                <Grid item>
                  <Typography fontStyle="italic" variant="body2">
                    {t('You will continue to receive and pay future invoices manually.')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </RadioGroup>
  );

  const bottomComponent = (() => {
    if (finalScreenMode && uiMode === UIModes.standalone) {
      return <YouCanCloseTheWindowNow />;
    }

    return (
      <LoadingButton
        variant="contained"
        loading={buttonLoading}
        onClick={() => {
          amplitude.trackButtonClick(
            PAGE_VIEWS.AUTOPAY_ENROLLMENT,
            'Select autopay consent',
            {},
            {
              autopayConsent: userAutopaySelection,
            },
          );
          setButtonLoading(true);
          submitAutopayConsent(userAutopaySelection);
        }}
        disabled={pageLoading}
      >
        {t('messageScreenContinue')}
      </LoadingButton>
    );
  })();

  return (
    <Screen
      showBackButton={false}
      showCloseButton={uiMode !== UIModes.standalone}
      bottomStickyComponent={bottomComponent}
    >
      <LinkHeader src={customizationInfo.logoUrl} message="Enroll into autopay" alt="Header logo" />
      <MidContainer sx={{ gap: 1, px: 3, py: 4 }}>{pageContent}</MidContainer>
    </Screen>
  );
}
