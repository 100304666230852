import { ComponentType } from 'react';

import { Subtract } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Product } from '../constants/linkStatusData/products';
import { ActionRequiredData } from '../entities';
import { LinkToken } from '../entities/api/token';
import useLoginHelper from '../hooks/useLoginHelper';
import useSearchParams from '../hooks/useSearchParams';
import { getErrorPath, getSuccessPath, parseToken } from '../pages/common/utils';
import { isLinkStatusActionRequiredData } from '../services/runtimeType';

type ParamTypes = {
  institutionId: string;
};

export interface InjectedLinkingProps {
  checkLinkStatus: () => Promise<void>;
}
type OtherProps = {
  institutionId?: string;
};

type ExposedProps<T extends InjectedLinkingProps> = Subtract<T, InjectedLinkingProps> & OtherProps;

const isPayments = (token: string) => {
  const decoded = parseToken<LinkToken>(token);
  const request = decoded?.linkTokenRequest ? JSON.parse(decoded.linkTokenRequest) : {};
  const productRequested = request.products_requested;
  const paymentInstructionId = request.payment_instruction_id;
  return Array.isArray(productRequested) && productRequested.includes(Product.payments) && paymentInstructionId;
};

const withLinking =
  <P extends InjectedLinkingProps>(WrappedComponent: ComponentType<P>): React.FC<ExposedProps<P>> =>
  // eslint-disable-next-line react/display-name
  (props: ExposedProps<P>) => {
    const location = useLocation<ActionRequiredData>();
    const { institutionId } = useParams<ParamTypes>();
    const { params } = useSearchParams();
    const userState = params.state;
    const history = useHistory();
    const { linkStatus } = useLoginHelper();

    const generateURLParams = (linkStatus: ActionRequiredData): URLSearchParams => {
      const searchParams = new URLSearchParams(location.search);
      if (isLinkStatusActionRequiredData(linkStatus)) {
        const { id } = linkStatus;
        if (id !== undefined || id !== null) searchParams.append('identifier', id);
      }
      return searchParams;
    };

    // Link Status checker
    let checkLinkStatusInProgress = false;
    const checkLinkStatus = async () => {
      if (checkLinkStatusInProgress) return;
      checkLinkStatusInProgress = true;

      try {
        // Get Link Status from Helper
        const status = await linkStatus();

        // Action Required Scenario
        if (isLinkStatusActionRequiredData(status)) {
          const searchParams = generateURLParams(status);
          history.push(`/onboarding/action/${institutionId}?${searchParams}`, {
            loginScreenData: { ...location.state },
            linkStatusData: { ...status },
          });
          return;
        } else {
          // check which set of success message should display
          let successMessage: { header: string; body: string };
          if (isPayments(params.token)) {
            successMessage = {
              header: 'paymentSuccessHeader',
              body: 'paymentSuccessBody',
            };
          } else {
            successMessage = {
              header: 'linkingHeader',
              body: 'linkingBody',
            };
          }

          history.push(
            getSuccessPath(
              {
                institutionId,
                userState,
                searchParamsString: location.search,
                ...successMessage,
              },
              status.code,
            ),
          );
        }
      } catch (error) {
        if (error.retry) {
          // This is when we have a NETWORK_ERROR. The reason for the error is the screens
          // going out of focus.
          // set checkLinkStatusInProgress to false so we will retry the linkstatus call again
          checkLinkStatusInProgress = false;
          return;
        }
        history.push(
          getErrorPath({
            institutionId,
            error,
            userState,
            destination: `/onboarding/login/${institutionId}`,
            searchParamsString: location.search,
          }),
        );
      }
    };

    return <WrappedComponent {...(props as P)} checkLinkStatus={checkLinkStatus} />;
  };

export default withLinking;
