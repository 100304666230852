import { ComponentProps, useState } from 'react';

import {
  Alert,
  createTheme,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LinkStatusFieldOption } from '../entities/api';
import useSearchParams from '../hooks/useSearchParams';
import { useStyles } from '../pages/common/styles';
import { getTheme } from '../services';

export type SelectFieldOption = LinkStatusFieldOption;
type Props = ComponentProps<typeof FormControl> &
  TextFieldProps & {
    id: string;
    label: string;
    selectClassName?: string;
    errorClassName?: string;
    required?: boolean;
    textFieldProps: SelectFieldOption[];
    institutionId: string;
  };

export default function SelectInput({
  id,
  label,
  required,
  selectClassName,
  errorClassName,
  textFieldProps,
  institutionId,
  helperText,
  ...props
}: Props): JSX.Element {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();
  const [selectedTextFieldProp, setSelectedTextFieldProps] = useState(textFieldProps[0]);
  const [elementSelected, setElementSelected] = useState(false);
  const { t, i18n } = useTranslation();
  const { params } = useSearchParams();
  const isLaunchedByDemoApp = params.demoApp === 'true';
  // Themes
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  const focusHandler = () => setElementSelected(true);
  const blurHandler = () => setElementSelected(false);

  const handleChange: ComponentProps<typeof Select>['onChange'] = (e) => {
    const textFieldProp = textFieldProps.find((prop) => e.target.value === prop.value);
    if (textFieldProp) {
      setSelectedTextFieldProps(textFieldProp);
      // Unregister the unselected options
      textFieldProps
        .filter((prop) => e.target.value !== prop.value)
        .forEach((prop) => {
          unregister(prop.value);
        });
    }
  };

  return (
    <>
      {institutionId === 'testbank-dbs-hk' && isLaunchedByDemoApp && (
        <Alert severity="info" style={{ marginTop: '-4%', marginBottom: '1.5rem', whiteSpace: 'pre-wrap' }}>
          {t('authMandateTestbankPersonalReminder')}
        </Alert>
      )}
      {institutionId === 'testbank-dbs-business' && isLaunchedByDemoApp && (
        <Alert severity="info" style={{ marginTop: '-4%', marginBottom: '1.5rem', whiteSpace: 'pre-wrap' }}>
          {t('authMandateTestbankBusinessReminder')}
        </Alert>
      )}
      <FormGroup className={classes.selectInputFormGroup}>
        <FormControl
          {...props}
          focused={elementSelected}
          error={errors[selectedTextFieldProp.value]}
          className={classes.selectInputSelection}
        >
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select
            key={`${id}-select`}
            labelId={`${id}-label`}
            defaultValue={selectedTextFieldProp.value}
            label={t(label)}
            className={selectClassName}
            onChange={handleChange}
            onFocus={focusHandler}
            onBlur={blurHandler}
          >
            {textFieldProps?.map((prop: SelectFieldOption) => (
              <MenuItem value={prop.value} key={prop.value}>
                {t(prop.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          {...register(selectedTextFieldProp.value, {
            ...(required && {
              required: { message: t('required', { label: t(selectedTextFieldProp.label) }), value: true },
            }),
            ...(selectedTextFieldProp.pattern && {
              pattern: {
                value: selectedTextFieldProp.pattern.value,
                message: t(selectedTextFieldProp.pattern.message),
              },
            }),
          })}
          id={`${id}-${selectedTextFieldProp.value}`}
          name={selectedTextFieldProp.value}
          key={selectedTextFieldProp.value}
          type="text"
          variant="outlined"
          placeholder={selectedTextFieldProp.placeholder}
          InputLabelProps={
            typeof selectedTextFieldProp.placeholder === 'string' && selectedTextFieldProp.placeholder !== ''
              ? { shrink: true }
              : {}
          }
          autoFocus={true}
          onFocus={focusHandler}
          onBlur={blurHandler}
          focused={elementSelected}
          error={errors[selectedTextFieldProp.value]}
          InputProps={{
            className: classes.selectInputTextInput,
          }}
          {...(props as TextFieldProps)}
        />
      </FormGroup>
      {errors[selectedTextFieldProp.value] && (
        <FormHelperText className={[classes.selectInputHelperText, errorClassName].join(' ')}>
          {errors[selectedTextFieldProp.value] && errors[selectedTextFieldProp.value].message}
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText className={classes.selectInputHelperText}>{t(helperText.toString())}</FormHelperText>
      )}
    </>
  );
}
