import { AxiosError, isAxiosError } from 'axios';
import { LocationDescriptor } from 'history';

import { ErrorResponse, UIModes } from '../entities';
import { GenericErrorRoutes } from '../routers/routes';
import { decodeToken } from '../services';

// This functions takes in the axios error and constructs the appropriate redirect url to the ApiErrorScreen
/**
 *
 * @param err The axios error from the failed request. If the error is not an axios error, then we will return a generic error screen
 * @param searchParamsString Can be with leading `?` or without.
 * @returns
 */
export function getApiErrorPath(err: AxiosError<ErrorResponse>, searchParamsString: string): LocationDescriptor {
  const searchParams = new URLSearchParams(searchParamsString);

  if (!isAxiosError(err) || err.response === undefined) {
    // not an axios error, lets just return generic error screen
    searchParams.set('message', 'errorGenericMessage');
    searchParams.set('details', 'errorGenericDetails');
    return {
      pathname: GenericErrorRoutes.ApiError,
      search: searchParams.toString(),
    };
  }

  // this is an axios error (we expect the payload to be of type ErrorResponse)
  const { message, details } = mapAxiosErrorToErrorText(err.response.status, err.response.data);

  searchParams.set('message', message);
  searchParams.set('details', details);
  return {
    pathname: GenericErrorRoutes.ApiError,
    search: searchParams.toString(),
  };
}

function mapAxiosErrorToErrorText(status: number, body: ErrorResponse): { message: string; details: string } {
  if (status === 401) {
    // for 401, we will return SESSION_EXPIRED message
    return {
      message: 'sessionExpiredMessage',
      details: 'sessionExpiredDetails',
    };
  }

  // In the future, we might want to add more logic here based on specific errors

  // default behaviour is to return generic error message and details
  return {
    message: 'errorGenericMessage',
    details: 'errorGenericDetails',
  };
}

// We should use this function as the `onClickNextStep` on error screens.
// This will allow us to have consistent actions across different error screens.
// Should pass in the `redirectToUri` from `useRedirectURI` hook as the redirect action
export function commonErrorCTAButtonAction(token: string, uiMode: UIModes, redirectAction: () => void) {
  const decodedToken = decodeToken(token);

  if (decodedToken.retryUrl !== undefined) {
    window.location.replace(decodedToken.retryUrl);
  } else if (uiMode === UIModes.redirect || uiMode === UIModes.autoRedirect) {
    redirectAction();
  } else {
    window.parent.postMessage('close', '*');
  }
}
