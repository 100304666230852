import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// BorderedGrid allow us to show a title in a grid that has a border
/**
kind of like the following
---- title----
|             |
--------------
**/
export const BorderedGrid = styled(Grid)(({ title }) => ({
  '&::before': {
    content: `"${title}"`,
    position: 'relative',
    fontStyle: 'italic',
    top: '-12px',
    left: '25%', // ideally should be calculated based on the title length
    backgroundColor: '#fff',
    padding: '0 10px',
  },
}));
