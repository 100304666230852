import { Dispatch, SetStateAction, useMemo, useState, createContext, ReactNode } from 'react';

interface IMandateInfoContext {
  mandateId: string;
  institutionInfo: InstitutionInfo;
  setMandateId: Dispatch<SetStateAction<string>>;
  setInstitutionInfo: Dispatch<SetStateAction<InstitutionInfo>>;
}

interface InstitutionInfo {
  bankCode: string;
  institutionName: string;
}

export const MandateInfoContext = createContext<IMandateInfoContext>({
  mandateId: '',
  institutionInfo: { bankCode: '', institutionName: '' },
  setMandateId: () => {
    null;
  },
  setInstitutionInfo: () => {
    null;
  },
});

export const MandateInfoProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [mandateId, setMandateId] = useState<string>('');
  const [institutionInfo, setInstitutionInfo] = useState<InstitutionInfo>({ bankCode: '', institutionName: '' });

  const contextValue = useMemo(
    () => ({
      mandateId,
      institutionInfo,
      setMandateId,
      setInstitutionInfo,
    }),
    [mandateId, setMandateId, institutionInfo, setInstitutionInfo],
  );

  return <MandateInfoContext.Provider value={contextValue}>{children}</MandateInfoContext.Provider>;
};
