import { useState, createContext, ReactNode, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

export interface IHistoryStackContext {
  historyStack: Array<string>;
  find: (pathname: string) => number | undefined;
}

export const HistoryStackContext = createContext<IHistoryStackContext>({
  historyStack: [],
  find: (p) => {
    return 0;
  },
});

export const HistoryStackProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const history = useHistory();
  const [historyStack, setHistoryStack] = useState<Array<string>>([history.location.pathname]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        setHistoryStack((prev) => [location.pathname, ...prev]);
      }
      if (action === 'POP') {
        const stack = [...historyStack];
        for (const item of historyStack) {
          if (item === location.pathname) {
            break;
          }
          stack.shift();
        }
        setHistoryStack(stack);
      }
      if (action === 'REPLACE') {
        // replace replaces first entry in stack only
        setHistoryStack((prev) => {
          const [_, ...rest] = prev;
          return [location.pathname, ...rest];
        });
      }
    });
    return () => unlisten();
  }, [history, historyStack]);

  const find = useCallback(
    (pathname: string) => {
      let dist = 0;
      for (const item of historyStack) {
        if (item === pathname) {
          break;
        }
        dist++;
      }
      return dist === historyStack.length ? undefined : -dist;
    },
    [historyStack],
  );

  return (
    <HistoryStackContext.Provider
      value={{
        historyStack: historyStack,
        find: find,
      }}
    >
      {children}
    </HistoryStackContext.Provider>
  );
};
