import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import Image, { ImageProps } from './Image';

type Props = {
  variant?: string;
};

const variants: Record<string, SxProps<Theme>> = {
  // Firefox only render a SVG if the size is absolute sized
  large: {
    maxWidth: '60%',
    width: '400px',
  },
  small: {
    maxWidth: '30%',
    width: '200px',
  },
  icon: {
    maxWidth: '4rem',
  },
  default: {
    maxWidth: '40%',
    width: '300px',
  },
};

export default function Logo({ variant, ...rest }: Props & ImageProps): JSX.Element {
  return (
    <Box
      sx={{
        placeSelf: 'center',
        ...variants[variant || 'default'],
      }}
    >
      <Image {...rest} />
    </Box>
  );
}
