import React, { Suspense, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './scss/styles.scss';
import './services/i18n';
import { ProductFlow } from './constants/onboarding';
import { ClientProvider } from './contexts/ClientContext';
import { HistoryStackProvider } from './contexts/HistoryStackContext';
import { LoginIdentityProvider } from './contexts/LoginIdentityContext';
import { MandateInfoProvider } from './contexts/MandateInfoContext';
import { UIModes } from './entities';
import useRedirectURI from './hooks/useRedirectURI';
import UnauthorizedScreen from './pages/common/UnauthorizedScreen';
import CybersourceRedirect from './pages/Cybersource/Redirect';
import PaymentGenericSuccessScreen from './pages/payment/GenericSuccess';
import Routers from './routers';
import {
  OnboardingRoutes,
  MandateRoutes,
  GenericErrorRoutes,
  FpsRoutes,
  CardRoutes,
  PaymentRoutes,
  PaymentMethodRoutes,
  CybersourceRedirectRoute,
} from './routers/routes';
import { decodeToken, getProductFlow } from './services';
import amplitude from './services/amplitude';
import { addQueryParamsToUrl } from './utils/url';

function App(): JSX.Element {
  const { setRedirectURI } = useRedirectURI();
  const search = window.location.search;

  useEffect(() => {
    amplitude.initialize();
    const params = new URLSearchParams(search);

    const token = params.get('token');
    const uiMode = params.get('ui_mode');
    if (token !== null && uiMode !== null) {
      try {
        const decodedToken = decodeToken(token);
        const productFlow = getProductFlow(token);
        if (uiMode === UIModes.redirect || uiMode === UIModes.autoRedirect) {
          if (
            productFlow === ProductFlow.PaymentLink ||
            productFlow === ProductFlow.FPS ||
            productFlow === ProductFlow.PaymentLinkSetup
          ) {
            const redirectParams = new URLSearchParams({
              payment_link_id: decodedToken.paymentLinkId,
              unique_reference_id: decodedToken.uniqueReferenceId,
            });

            const redirectWithParams = addQueryParamsToUrl(decodedToken.redirectUri, redirectParams);
            setRedirectURI(redirectWithParams);
          }
          if (productFlow === ProductFlow.Mandate) {
            const redirectUri = addQueryParamsToUrl(
              decodedToken.redirectUri,
              new URLSearchParams({ mandate_id: decodedToken.mandateId }),
            );
            setRedirectURI(redirectUri);
          }
        }
      } catch (e) {
        // do not throw
      }
    }

    return () => {
      amplitude.cleanup();
    };
  }, [search, setRedirectURI]);

  return (
    <React.Fragment>
      <CssBaseline />
      <ClientProvider>
        <LoginIdentityProvider>
          <MandateInfoProvider>
            <Router>
              <HistoryStackProvider>
                <div className="root">
                  <Switch>
                    <Route path={GenericErrorRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.ErrorRouter />
                      </Suspense>
                    </Route>
                    <Route path={PaymentRoutes.GenericSuccess}>
                      <Suspense fallback="">
                        <PaymentGenericSuccessScreen />
                      </Suspense>
                    </Route>
                    <Route path={OnboardingRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.OnboardingRouter />
                      </Suspense>
                    </Route>
                    <Route path={MandateRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.MandateRouter />
                      </Suspense>
                    </Route>
                    <Route path={FpsRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.FpsRouter />
                      </Suspense>
                    </Route>
                    <Route path={CardRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.CardRouter />
                      </Suspense>
                    </Route>
                    <Route path={PaymentMethodRoutes.Base}>
                      <Suspense fallback="">
                        <Routers.PaymentMethodRouter />
                      </Suspense>
                    </Route>
                    <Route path={CybersourceRedirectRoute}>
                      <CybersourceRedirect />
                    </Route>
                    <Route path="/unauthorized">
                      <UnauthorizedScreen />
                    </Route>
                  </Switch>
                </div>
              </HistoryStackProvider>
            </Router>
          </MandateInfoProvider>
        </LoginIdentityProvider>
      </ClientProvider>
    </React.Fragment>
  );
}

export default App;
