export enum CardStatus {
  UNKNOWN = 'UNKNOWN',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
}

interface CardDetailsFvLink {
  brand: string;
  last4: string;
}

interface CardRecipient {
  name: string;
}

export interface CardFvLinkResponse {
  status: string;
  card_details: CardDetailsFvLink;
  recipient: CardRecipient;
}
