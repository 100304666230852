import { UIModes } from '../entities';

export const parseUIMode = (uiMode: string): UIModes => {
  switch (uiMode) {
    case 'redirect':
      return UIModes.redirect;
    case 'auto_redirect':
      return UIModes.autoRedirect;
    case 'standalone':
      return UIModes.standalone;
    case 'iframe':
    default:
      return UIModes.iframe;
  }
};
