import { CardFvLinkResponse } from './card';
import { MandateFvLinkResponse } from './mandate';

interface PaymentMethodFvLinkResponseBase {
  payment_method_id: string;
  payment_method_type: PaymentMethodType;
  recipient_entity_name: string;
}

export enum PaymentMethodType {
  UNKNOWN = 'UNKNOWN',
  MANDATE = 'MANDATE',
  CARD = 'CARD',
}

type PaymentMethodFvLinkUnknown = PaymentMethodFvLinkResponseBase & {
  payment_method_type: PaymentMethodType.UNKNOWN;
};

type PaymentMethodFvLinkMandate = PaymentMethodFvLinkResponseBase & {
  payment_method_type: PaymentMethodType.MANDATE;
  mandate: MandateFvLinkResponse;
};

type PaymentMethodFvLinkCard = PaymentMethodFvLinkResponseBase & {
  payment_method_type: PaymentMethodType.CARD;
  card: CardFvLinkResponse;
};

export type PaymentMethodFvLinkResponse =
  | PaymentMethodFvLinkUnknown
  | PaymentMethodFvLinkMandate
  | PaymentMethodFvLinkCard;

export interface ChangePaymentMethodResponse {
  redirect_url: string;
}
