import { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { supportedLanguage } from '../config';
import useSearchParams from '../hooks/useSearchParams';
import { buildSearchParams } from '../pages/common/utils';

const LanguageSwitch = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { searchParamsString, params } = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const paramLanguage = params.language;

  // This block of code ensures that when we land on Finverse Link, the language passed through query params is set
  useEffect(() => {
    // i.e. language is not current language and it is supported
    if (i18n.language !== paramLanguage && Object.keys(supportedLanguage).includes(paramLanguage)) {
      i18n.changeLanguage(paramLanguage);
    }
  }, [paramLanguage, i18n]);

  const handleClick = useCallback((event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(null);
      const lang = (event.target as Element).id;
      if (lang === '') {
        // this could happen if user opens translation tab but does not select anything
        return;
      }
      i18n.changeLanguage(lang);
      const nextParams = buildSearchParams({ language: lang }, searchParamsString);
      history.replace({
        pathname: window.location.pathname,
        state: location.state,
        search: nextParams,
      });
    },
    [history, i18n, location.state, searchParamsString],
  );

  return (
    <FormControl>
      <Button
        variant="text"
        size="medium"
        color="inherit"
        aria-label="language-switch"
        onClick={handleClick}
        startIcon={<LanguageIcon />}
        endIcon={<ArrowDownIcon />}
      >
        {i18n.language}
      </Button>
      <Menu id="language-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.entries(supportedLanguage).map(([k, v]) => (
          <MenuItem key={k} id={k} onClick={handleClose} selected={k === paramLanguage}>
            {v}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default LanguageSwitch;
