import { ComponentType } from 'react';

import { Subtract } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { LoadingScreenData } from '../constants/linkStatusData/linkStatusData';
import { ActionRequiredScreenState, FINVERSE_OAUTH_ERROR, LoginResponse } from '../entities';
import LinkError from '../entities/LinkError';
import useLoginHelper from '../hooks/useLoginHelper';
import useSearchParams from '../hooks/useSearchParams';
import { getErrorPath } from '../pages/common/utils';
import { InjectedLinkingProps } from './withLinking';

type ParamTypes = {
  institutionId: string;
};

export type LoadingMessage = {
  title: string;
  message1: string;
  message2: string;
};

export type SubmitData = {
  screenName: string | null | undefined;
};

export interface InjectedSubmitAndActionProps {
  onSubmit: (data: Record<string, unknown>, submitData: SubmitData, timeout?: number) => Promise<void>;
}

type OtherProps = InjectedLinkingProps;

type ExposedProps<T extends InjectedSubmitAndActionProps> = Subtract<T, InjectedSubmitAndActionProps> & OtherProps;

// This HOC is not coupled with withLinking HOC
const withSubmitAndAction =
  <P extends InjectedSubmitAndActionProps>(WrappedComponent: ComponentType<P>): React.FC<ExposedProps<P>> =>
  // eslint-disable-next-line react/display-name
  (props: ExposedProps<P>) => {
    const history = useHistory();
    const location = useLocation<ActionRequiredScreenState>();

    // state data
    const loginScreenData = location.state?.loginScreenData;

    // state params
    const { institutionId } = useParams<ParamTypes>();

    // search params
    const { params } = useSearchParams();
    const userState = params.state ?? '';

    // Contexts
    const { submit, performAction: action } = useLoginHelper();

    const generateURLParams = (linkStatusId: string): URLSearchParams => {
      const searchParams = new URLSearchParams(location.search);
      // Need to delete identifier which is set by with linking
      searchParams.delete('identifier');
      // Need to add link staus which is used by with linking
      searchParams.set('linkStatusId', linkStatusId);
      return searchParams;
    };

    const navigateToLink = (linkStatusId: string) => {
      const searchParams = generateURLParams(linkStatusId);
      history.push(`/onboarding/action/${institutionId}?${searchParams}`, { linkStatusData: LoadingScreenData });
    };

    const performAction = async (
      data: Record<string, string>,
      submitData: SubmitData,
    ): Promise<LoginResponse | null> => {
      return action({ data });
    };

    const performSubmit = async (data: Record<string, string>) => {
      const { storeCredentials, ...inputs } = loginScreenData;
      return await submit({ ...inputs, ...data }, loginScreenData.storeCredentials);
    };

    const onSubmit = async (
      formData: Record<string, string>,
      submitData: SubmitData,
      timeout = 5000,
    ): Promise<void> => {
      try {
        let result: LoginResponse | null;
        const { screenName } = submitData;

        switch (screenName) {
          case 'SECOND_PASSWORD': {
            // For now we should only be having one key for a second password page
            // Thus, choosing the first one
            result = await performSubmit(formData);
            break;
          }
          case 'PUSH_SENT': {
            // Add timeout for now
            await new Promise((resolve) => setTimeout(resolve, timeout));
            result = await performAction(formData, submitData);
            if (result === undefined || result === null || result.linkStatusId === undefined) {
              throw new Error('Error during push sent');
            } else {
              await props.checkLinkStatus();
            }
            return;
          }
          case 'DEVICE_SELECTION':
          case 'ACCOUNT_SELECTION':
          case 'SMS_OTP':
          case 'TOKEN_OTP':
          case 'MOBILE_OTP':
          case 'CONFIRMATION':
          case 'DATE_OF_BIRTH':
            result = await performAction(formData, submitData);
            break;
          default: {
            throw new Error('Unknown screen name');
          }
        }

        if (result === null || result.linkStatusId === undefined) {
          throw new LinkError(FINVERSE_OAUTH_ERROR);
        }
        navigateToLink(result.linkStatusId);
      } catch (error) {
        history.push(
          getErrorPath({
            institutionId,
            error,
            userState,
            searchParamsString: location.search,
            destination: `/onboarding/login/${institutionId}`,
          }),
        );
      }
    };

    return <WrappedComponent {...(props as unknown as P)} onSubmit={onSubmit} />;
  };

export default withSubmitAndAction;
