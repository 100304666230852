export enum ProductFlow {
  Unknown = 'unknown',
  DataRetrieval = 'data_retrieval',
  Mandate = 'mandate',
  BankDirectDebit = 'bank_direct_debit',
  PaymentLink = 'payment_link',
  PaymentLinkSetup = 'payment_link_setup',
  RefreshDataRetrieval = 'refresh_data_retrieval',
  FPS = 'fps',
}

// The actual value we want to submit to amplitude has different naming
export const getAmplitudeProductFlow = (flow: ProductFlow): string => {
  switch (flow) {
    case ProductFlow.DataRetrieval:
      return 'DATA_RETRIEVAL';
    case ProductFlow.RefreshDataRetrieval:
      return 'REFRESH_DATA_RETRIEVAL';
    case ProductFlow.Mandate:
      return 'MANDATE';
    case ProductFlow.PaymentLink:
    case ProductFlow.FPS:
      return 'PAYMENT_LINK';
    case ProductFlow.PaymentLinkSetup:
      return 'PAYMENT_LINK_SETUP';
    case ProductFlow.BankDirectDebit:
      return 'BDO_PAYMENT_INSTRUCTION';
    default:
      return 'UNKNOWN';
  }
};

export const getAmplitudeOnboardingFlow = (flow: OnboardingFlow): string => {
  switch (flow) {
    case OnboardingFlow.PaymentLink:
      return 'PAYMENT_LINK';
    case OnboardingFlow.ChangePaymentMethod:
      return 'CHANGE_PAYMENT_METHOD';
    default:
      return 'UNKNOWN';
  }
};

export enum OnboardingFlow {
  Unknown = 'unknown',
  PaymentLink = 'payment_link',
  ChangePaymentMethod = 'change_payment_method',
}

export enum MandateAuthorizationScreens {
  MANDATE_AUTHORIZATION = 'MANDATE_AUTHORIZATION',
  MANDATE_AUTHORIZATION_CONSENT = 'MANDATE_AUTHORIZATION_CONSENT',
  MANDATE_AUTHORIZATION_CHECK_STATUS = 'MANDATE_AUTHORIZATION_CHECK_STATUS',
}

export enum MandateStatus {
  UNKNOWN = 'UNKNOWN',
  AUTHORIZATION_REQUIRED = 'AUTHORIZATION_REQUIRED',
  PROCESSING = 'PROCESSING',
  SUBMITTED = 'SUBMITTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  REVOKED = 'REVOKED',
  READY_TO_SUBMIT = 'READY_TO_SUBMIT',
}

// we expect this to be populated on onboarding token and on FPS token
export enum ManualPaymentProvider {
  UNKNOWN = 'UNKNOWN',
  HK_FPS = 'HK_FPS',
  SG_PAYNOW = 'SG_PAYNOW',
}

export enum FuturePayments {
  UNKNOWN = 'UNKNOWN',
  AUTOPAY = 'AUTOPAY',
  CLICK_TO_PAY = 'CLICK_TO_PAY',
}

export default {
  ProductFlow,
  MandateAuthorizationScreens,
};
