import { TFunction } from 'react-i18next';

import { MandateAuthorizationScreens, ProductFlow } from '../constants/onboarding';
import {
  ActionRequiredData,
  LinkStatusField,
  LinkStatusFieldOptionPattern,
  LinkStatusMessage,
  LinkStatusMessageType,
  LinkStatusType,
} from '../entities';
import { AuthChecklist, AuthChecklistItem } from '../entities/api/authChecklist';

export const parseAuthChecklist = (
  t: TFunction,
  authChecklist: AuthChecklist,
  productFlow: ProductFlow,
  recipientName: string,
  consentData?: { name: string; value: string }[],
): ActionRequiredData[] => {
  // @rsaxena_f - the code below expects the authChecklist to be sorted in reverse order, i.e.
  // the last screen (consent) to be first in the array, and the first at the end.
  // Since API gives the auth checklist in "correct" order, we simply reverse it for now
  // A better long term fix is to rewrite this function, preferring readability over inline functions and such.
  const revList = authChecklist.reverse();

  /**
   * First, we separate the auth checklist items into their pages
   * All the items will be on a single page, except for CONSENT, which would be on its own page.
   * If CONSENT is in between different items, then it would split those into separate pages as well
   *
   * @example
   * if checkList = [A, B, CONSENT, C, D]
   * then pages = [[A, B], [CONSENT], [C, D]]
   *
   * @example
   * if checkList = [A, B, CONSENT]
   * then pages = [[A, B], [CONSENT]]
   *
   */

  const pages: AuthChecklist[] = [];
  let currentPage: AuthChecklistItem[] = [];

  for (let i = 0; i < revList.length; i++) {
    const checklistItem = revList[i];

    // This is a consent item, it needs a standalone page
    if (checklistItem.type.includes('CONSENT')) {
      // dump currentPage into pages[] if not empty
      if (currentPage.length !== 0) {
        pages.push(currentPage);
        currentPage = [];
      }

      // add the consent item in its own page
      pages.push([checklistItem]);
    } else {
      // just add it to the current page
      currentPage.push(checklistItem);
    }
  }

  // If currentPage has anything in it, add it as a page
  if (currentPage.length !== 0) {
    pages.push(currentPage);
  }

  return pages.map((checklist) => {
    const fields: LinkStatusField[] = [];
    const messages: LinkStatusMessage[] = [];
    let name = MandateAuthorizationScreens.MANDATE_AUTHORIZATION;
    for (const checklistItem of checklist) {
      if (checklistItem.type === 'ENDUSER_CONSENT') {
        // We change the name to allow to submit consent true in action required
        name = MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CONSENT;
        // We append end user consent to messages
        messages.push({
          type: LinkStatusMessageType.BODY,
          name: 'CONFIRM_MANDATE_AUTHORIZATION_DETAILS',
          value: 'Please confirm the following details:',
        });
        (consentData ?? []).forEach(({ name, value }) =>
          messages.push({ type: LinkStatusMessageType.TABLE_ROW, name, value }),
        );
        messages.push(...getFooterTexts(t, productFlow, recipientName));
      } else {
        fields.push(parseAuthChecklistItem(checklistItem));
      }
    }
    return {
      id: '',
      type: LinkStatusType.ACTION,
      name,
      messages,
      fields,
      buttons: [
        {
          name: 'CONFIRM',
          type: 'SUBMIT',
          value: 'Confirm',
        },
      ],
    };
  });
};

const parseAuthChecklistItem = (authChecklistItem: AuthChecklistItem): LinkStatusField => {
  if (authChecklistItem.options.length === 1) {
    const mappedProps = mapItemToProps(authChecklistItem.options[0].name);
    return {
      ...mappedProps,
      name: authChecklistItem.options[0].name,
      required: authChecklistItem.required === 'YES' ? true : false,
      type: 'INPUT',
      helperText: authChecklistItem.helper_text,
    };
  }

  const label = snakeCaseToTitleCase(authChecklistItem.type);
  const options = authChecklistItem.options.map((item) => ({
    ...mapItemToProps(item.name),
    value: item.name,
  }));
  return {
    label,
    options,
    type: 'SELECT_INPUT',
    required: authChecklistItem.required === 'YES' ? true : false,
    name: '', // Individual option 'value' will be used instead
    placeholder: '', // Individual option 'placeholder' should be used instead
    helperText: authChecklistItem.helper_text,
  };
};

const mapItemToProps = (
  itemName: string,
): {
  label: string;
  placeholder?: string;
  pattern?: LinkStatusFieldOptionPattern;
} => {
  switch (itemName) {
    case 'HK_ID':
      return {
        label: 'HK ID',
        placeholder: 'A1234567',
        pattern: {
          value: /^[A-Z]{1,2}[0-9]{6}[0-9A]$/,
          message: 'Wrong HK ID format. The format should be "A1234567".',
          isNumeric: false,
        },
      };
    case 'PASSPORT':
      return {
        label: 'Passport Number',
        placeholder: 'D12345678',
      };
    case 'ACCOUNTHOLDER_NAME':
      return {
        label: 'Account Holder Name',
        placeholder: 'John Doe',
      };
    case 'ACCOUNT_NUMBER':
      return {
        label: 'Account Number',
        placeholder: '123456789012',
        pattern: {
          value: /^[0-9]+$/,
          message: 'Value must be numeric',
          isNumeric: true,
        },
      };
    case 'HK_BUSINESS_REGISTRATION':
      return {
        label: 'HK Business Registration Number',
        placeholder: '12345678',
        pattern: {
          value: /^[0-9]{8}$/,
          message: 'The number is the 8 numerals at the front of business registration certificate number.',
          isNumeric: true,
        },
      };
    case 'HK_CERTIFICATE_OF_INCORPORATION':
      return {
        label: 'HK Certificate of Incorporation',
        placeholder: '1234567',
      };
    default:
      return {
        label: snakeCaseToTitleCase(itemName),
      };
  }
};

export const snakeCaseToTitleCase = (input: string): string =>
  input
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

const getFooterTexts = (t: TFunction, productFlow: ProductFlow, recipientName: string): LinkStatusMessage[] => {
  const footerMessages: LinkStatusMessage[] = [];
  let footerMessageValue =
    t('We will setup a direct debit authorization from your bank account to Finverse, for payments to') +
    ': ' +
    recipientName +
    '.';
  if (productFlow === ProductFlow.PaymentLink) {
    footerMessageValue = `${footerMessageValue} ${t('No payment will be made without your approval.')}`;
  }

  footerMessages.push({
    type: LinkStatusMessageType.FOOTER,
    name: 'CONFIRM_MANDATE_DISCLAIMER',
    value: footerMessageValue,
  });

  return footerMessages;
};
