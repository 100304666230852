import { useCallback, useEffect } from 'react';

import { CircularProgress, createTheme, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { ErrorResponse, UIModes } from '../../../entities';
import { PaymentStatus } from '../../../entities/api/payment';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { GenericErrorRoutes, MandateRoutes, PaymentRoutes } from '../../../routers/routes';
import { getTheme } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { isPaymentMethodSucceeded } from '../../../utils/payment_method';
import sleep from '../../../utils/sleep';
import Screen from '../../common/Screen';
import { useStyles } from '../../common/styles';

export default function PaymentPollingScreen(): JSX.Element {
  const { t } = useTranslation();
  const theme = createTheme(getTheme());
  const classes = useStyles(theme);
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const client = useAPIClient();
  const history = useHistory();

  const token = params.token;

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.PAYMENT_POLLING_SCREEN);
  });

  const pollPayment = useCallback(async () => {
    try {
      const paymentMethod = await client.getPaymentMethod(token);
      if (!isPaymentMethodSucceeded(paymentMethod)) {
        // if payment method is not succeeded, then we will not poll for payment
        return history.push({
          pathname: MandateRoutes.PaymentSubmitted,
          search: searchParamsString,
        });
      }
    } catch (e) {
      const err = e as AxiosError<ErrorResponse, any>;
      return history.push(getApiErrorPath(err, searchParamsString));
    }

    for (let i = 0; i < 20; i++) {
      await sleep(1000);
      try {
        const paymentRsp = await client.getPayment(token);

        if (paymentRsp.status === PaymentStatus.EXECUTED) {
          return history.push({
            pathname: PaymentRoutes.GenericSuccess,
            search: searchParamsString,
          });
        }

        if (paymentRsp.status === PaymentStatus.FAILED) {
          const newSearchParams = new URLSearchParams(searchParamsString);
          newSearchParams.set('title', 'Payment Failed');
          newSearchParams.set('message', paymentRsp.error.message);
          newSearchParams.set('details', paymentRsp.error.details);
          return history.push({
            pathname: GenericErrorRoutes.ApiError,
            search: newSearchParams.toString(),
          });
        }
      } catch (e) {
        const err = e as AxiosError<ErrorResponse, any>;
        if (err.response?.data.error.error_code === 'RESOURCE_NOT_FOUND') {
          // if we get RESOURCE_NOT_FOUND, treat it as pending payment, i.e. keep polling
          continue;
        }
        return history.push(getApiErrorPath(err, searchParamsString));
      }
    }

    return history.push({
      pathname: MandateRoutes.PaymentSubmitted,
      search: searchParamsString,
    });
  }, [client, token, history, searchParamsString]);

  useEffect(() => {
    pollPayment();
  }, [pollPayment]);

  return (
    <Screen showCloseButton={params.uiMode !== UIModes.standalone} showBackButton={false}>
      <LinkHeader src={customizationInfo.logoUrl} alt="Header logo" message="" />
      <MidContainer>
        <div className={classes.circularProgress}>
          <CircularProgress variant="indeterminate" size={78} color="primary" />
        </div>
        <Typography variant="h5" align="center" gutterBottom color="primary">
          {t('processing')}...
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {t('Please wait while we process your payment')}
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {t('messages_process_time_info_default')}
        </Typography>
      </MidContainer>
    </Screen>
  );
}
