import useSWR from 'swr';

import useAPIClient from './useClient';
import useSearchParams from './useSearchParams';

export type AppCustomizationInfo = {
  displayName?: string;
  logoUrl: string;
};

export const defaultCustomization: AppCustomizationInfo = {
  logoUrl: '/img/institutions/default.svg',
  displayName: 'App',
};

const useCustomizations = () => {
  const { params } = useSearchParams();
  const client = useAPIClient();
  const { data, error } = useSWR(
    ['/customization', params.token],
    (_: string, token: string) => client.getCustomization(token),
    { errorRetryCount: 0 },
  );

  const customizationInfo: AppCustomizationInfo = {
    displayName: data?.display_name,
    logoUrl: data?.logo_id ? `https://storage.googleapis.com/${data.logo_id}` : defaultCustomization.logoUrl,
  };

  return {
    customizationInfo,
    error,
  };
};

export default useCustomizations;
