import { useCallback, useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import { ErrorResponse } from '../entities';
import { decodeToken } from '../services';
import useAPIClient from './useClient';
import useSearchParams from './useSearchParams';

export enum usePostPaymentFlowFlag {
  NO_FLAG = 0,
  /**
   * This flag will cause the hook to return postFlowEnabled `false` without doing any processing
   */
  FORCE_DISABLE,
}

/**
 * This hooks advises on whether or not the post-flow is enabled.
 *
 * Since this hook depends on an async action, it is important that the caller only use the result AFTER `processing = false`
 *
 * Current conditions:
 * 1. displayEnrollmentScreen claim in token should be `true`
 * 2. paymentMethodId claim in token should be non-empty
 * 3. payment user should have autopay consent != true
 * 4. payment method should be submitted or succeeded
 * 5. payment (if applicable) should be queued, submitted or succeeded
 *
 * We will only check 1-3; the last 2 are implied based on their position in the flow
 */
export default function usePostPaymentFlow(flag = usePostPaymentFlowFlag.NO_FLAG) {
  const [processing, setProcessing] = useState(true);
  const [postFlowEnabled, setPostFlowEnabled] = useState(false);
  const [error, setError] = useState<AxiosError<ErrorResponse, unknown>>();
  const { params } = useSearchParams();
  const token = params.token;
  const client = useAPIClient();

  const validatePaymentUserAutopay = useCallback(
    async (token: string) => {
      try {
        const paymentUser = await client.getNonSensitivePaymentUserInfo(token);
        // if autopay consent is true, then we don't show post-flow; else we DO show post-flow
        setPostFlowEnabled(paymentUser.autopay_consent !== true);
        setProcessing(false);
      } catch (e) {
        const err = e as AxiosError<ErrorResponse, unknown>;
        setError(err);
      } finally {
        setProcessing(false);
      }
    },
    [client],
  );

  useEffect(() => {
    if (flag === usePostPaymentFlowFlag.FORCE_DISABLE) {
      return;
    }

    const decodedToken = decodeToken(token);

    // token should have displayEnrollmentScreen = true
    if (decodedToken.displayEnrollmentScreen !== 'true') {
      setPostFlowEnabled(false);
      setProcessing(false);
      return;
    }

    // token should have some non-empty payment method id claim
    if (typeof decodedToken.paymentMethodId !== 'string' || decodedToken.paymentMethodId === '') {
      setPostFlowEnabled(false);
      setProcessing(false);
      return;
    }

    validatePaymentUserAutopay(token);
  }, [flag, token, validatePaymentUserAutopay]);

  if (flag === usePostPaymentFlowFlag.FORCE_DISABLE) {
    return { processing: false, postFlowEnabled: false };
  }

  return {
    processing,
    postFlowEnabled,
    error,
  };
}
