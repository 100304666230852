import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons';
import { UIModes } from '../../entities';
import useCustomizations from '../../hooks/useCustomizations';
import usePostPaymentFlow from '../../hooks/usePostPaymentFlow';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { MandateRoutes } from '../../routers/routes';
import { getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { getApiErrorPath } from '../../utils/error_page';
import { GenericLoadingScreen } from '../common/Screen';
import { GenericInfoScreen } from '../Onboarding/MessageScreen';
import { BottomContainerContent } from './BottomContainer';

export default function PaymentGenericSuccessScreen(): JSX.Element {
  const { t } = useTranslation();
  const theme = createTheme(getTheme());
  const { customizationInfo } = useCustomizations();
  const { postFlowEnabled, processing, error } = usePostPaymentFlow();
  const history = useHistory();
  const title = 'success';
  const header = 'Payment completed';
  const body = 'Your payment has been received and successfully processed';

  const { params, searchParamsString } = useSearchParams();
  const { setRedirectURI } = useRedirectURI();

  useEffect(() => {
    if (params.uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [params.uiMode, params.redirect_uri, setRedirectURI]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS, { title, body, header });
  });

  if (processing) {
    return <GenericLoadingScreen />;
  }

  if (error !== undefined) {
    return <Redirect to={getApiErrorPath(error, searchParamsString)} />;
  }

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={params.uiMode as UIModes}
      showBackButton={false} // don't show back button; user might think we processed payment >1 times
      title={title}
      logoUrl={customizationInfo.logoUrl}
      iconComponent={<CheckIcon color="primary" />}
      titleComponent={
        <Typography variant="h5" fontWeight="bold">
          {t(header)}
        </Typography>
      }
      pageContentComponent={<Typography>{t(body)}</Typography>}
      bottomComponent={
        postFlowEnabled ? (
          <PrimaryButton
            variant="contained"
            onClick={() =>
              history.push({
                pathname: MandateRoutes.AutopayConsent,
                search: searchParamsString,
              })
            }
          >
            {t('messageScreenContinue')}
          </PrimaryButton>
        ) : (
          <BottomContainerContent />
        )
      }
    />
  );
}
