import { MandateStatus } from '../constants/onboarding';
import { PaymentMethodFvLinkResponse, PaymentMethodType } from '../entities/api/paymentMethod';

export const getRecipientNameFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): string => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.recipient.name;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return paymentMethod.card.recipient.name;
  }

  return 'UNKNOWN';
};

type PaymentMethodStatuses = {
  // this one is just for tracking / amplitude
  realStatus: string;
  // this is the one we will actually use
  mappedStatus: string;
};

export const getStatusFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): PaymentMethodStatuses => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    // We have a manual override from 'READY_TO_SUBMIT' -> 'PROCESSING'
    // See: https://www.notion.so/finverse/135e58711af28066ac53cd82657305ec
    if (paymentMethod.mandate.mandate_status === 'READY_TO_SUBMIT') {
      return {
        realStatus: paymentMethod.mandate.mandate_status,
        mappedStatus: 'PROCESSING',
      };
    }

    return {
      realStatus: paymentMethod.mandate.mandate_status,
      mappedStatus: paymentMethod.mandate.mandate_status,
    };
  }

  if (paymentMethod.payment_method_type == PaymentMethodType.CARD) {
    return {
      realStatus: paymentMethod.card.status,
      mappedStatus: paymentMethod.card.status,
    };
  }

  return {
    realStatus: 'UNKNOWN',
    mappedStatus: 'UNKNOWN',
  };
};

export const getMaskedAccountNumberFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): string => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    if (paymentMethod.mandate.sender_account !== undefined) {
      return (paymentMethod.mandate.sender_account.account_number_masked ?? '').replaceAll('X', '*');
    }
    return '';
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    const last4 = paymentMethod.card.card_details?.last4;
    return last4 !== '' ? `****${last4}` : '';
  }

  return '';
};

export const isPaymentMethodSucceeded = (paymentMethod: PaymentMethodFvLinkResponse): boolean => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.mandate_status === MandateStatus.SUCCEEDED;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return paymentMethod.card.status === 'SUCCEEDED';
  }

  return false;
};
