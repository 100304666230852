import { Switch, Route } from 'react-router-dom';

import ConfirmFpsScreen from '../pages/payment/fps/ConfirmFpsScreen';
import FpsSubmittedScreen from '../pages/payment/fps/FpsSubmittedScreen';
import SelectAccountHolderNameScreen from '../pages/payment/fps/SelectAccountHolderName';
import { FpsRoutes } from './routes';

export default function FpsRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={FpsRoutes.SelectAccHolder}>
        <SelectAccountHolderNameScreen />
      </Route>
      <Route path={FpsRoutes.PaymentSubmitted}>
        <FpsSubmittedScreen />
      </Route>
      <Route path={FpsRoutes.PaymentConfirm}>
        <ConfirmFpsScreen />
      </Route>
    </Switch>
  );
}
