import { Component } from 'react';

import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import MessageScreen from '../pages/Onboarding/MessageScreen';

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<RouteComponentProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return <MessageScreen />;
    }
    return <>{this.props.children}</>;
  }
}

export default withRouter(ErrorBoundary);
