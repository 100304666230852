import { TypographyProps, Typography as MuiTypography, TypographyVariant } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LinkStatusMessage, LinkStatusMessageType } from '../entities/api';

export type TypographyProp = Omit<TypographyProps, 'variant' | 'color'> &
  Partial<LinkStatusMessage> & {
    variant?: TypographyProps['variant'] | LinkStatusMessageType;
    color?: TypographyProps['color'] | LinkStatusMessageType;
  };

type TypographyColor =
  | undefined
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';

export const JSONTypography = ({
  type,
  variant: variantProp,
  color: colorProp,
  children,
  ...rest
}: TypographyProp): JSX.Element => {
  const getVariant = () => {
    const variant = type ?? variantProp;
    switch (variant) {
      case 'TITLE':
        return 'h5';
      case 'BODY':
      case 'HIGHLIGHT':
      case 'FOOTER':
        return 'body1';
      default:
        return variant as TypographyVariant;
    }
  };

  const getColor = (): TypographyColor => {
    const color = type ?? colorProp;

    switch (color) {
      case 'TITLE':
      case 'HIGHLIGHT':
        return 'primary';
      case 'BODY':
      case 'FOOTER':
        return 'textPrimary';
      default:
        return color as TypographyColor;
    }
  };

  return (
    <MuiTypography variant={getVariant()} color={getColor()} {...rest}>
      {children}
    </MuiTypography>
  );
};

export const ContentHeader = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 800,
})) as typeof MuiTypography;
