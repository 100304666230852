import makeStyles from '@mui/styles/makeStyles';

// mui v5 do not expose theme property as they moving away from using makeStyle/useStyle pattern
// As a result zIndex now hardcoded by using value obtained from mui documentation
export const useStyles = makeStyles((theme) => ({
  relative: {
    position: 'relative',
  },
  backdrop: {
    zIndex: 1200 + 1,
    color: '#fff',
  },
  formContainer: {
    padding: '3%',
  },
  fullViewHeight: {
    minHeight: '100vh',
  },
  headerHeight: {
    minHeight: '25vh',
  },
  actionRequiredPadding: {
    padding: '5%',
  },
  actionRequiredTopPadding: {
    paddingTop: '15%',
  },
  actionRequiredTextInput: {
    marginBottom: '1.5rem !important',
    width: '100%',
    backgroundColor: '#FFFFFF',
    '& label': {
      color: '#ABABAB',
      top: '-4px',
    },
    minHeight: '46px',
    '& input': {
      height: '16px',
    },
    '& div': {
      height: '46px',
    },
  },
  actionRequiredSelection: {
    height: '46px',
    width: '100%',
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
  },
  selectInputFormGroup: {
    width: '100%',
    flexWrap: 'nowrap',
    '&:hover': {
      '& fieldset': {
        borderColor: 'black',
      },
    },
  },
  selectInputTextInput: {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    '& fieldset': { borderTop: '0.25px' },
  },
  selectInputSelection: {
    backgroundColor: '#FFFFFF',
    '& label': {
      color: '#ABABAB',
      top: '-4px',
    },
    minHeight: '46px',
    '& input': {
      height: '16px',
    },
    '& div': {
      height: '46px',
    },
  },
  selectInputHelperText: {
    marginTop: '-1.5rem !important',
    marginBottom: '1.5rem !important',
  },
  textInput: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    height: '46px',
    '& input': {
      height: '9px',
    },
    '& label': {
      color: '#ABABAB',
      top: '-5px',
    },
  },
  actionRequiredButton: {
    fontWeight: 800,
    marginTop: '1.3rem',
    textTransform: 'none',
    height: '49px',
    fontSize: '18px',
  },
  button: {
    fontWeight: 800,
    textTransform: 'none',
    height: '49px',
    fontSize: '18px',
  },
  controlLabel: {
    marginRight: '5px',
  },
  icon: {
    fontSize: '17px',
    verticalAlign: 'middle',
  },
  link: {
    fontSize: '15px',
    marginTop: '10px',
    display: 'block',
    textAlign: 'center',
  },
  tooltip: {
    fontSize: '0.9rem',
  },
  error: {
    color: '#E20404',
    margin: 0,
    // marginBottom: '0.5rem', removing because we seem to be explicitly overriding this everywhere
  },
  textInlineImage: {
    height: '1.25em',
    verticalAlign: 'text-bottom',
  },
  container: {
    display: 'flex',
    opacity: '1 !important',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
  },
  logo: {
    zIndex: 1200 + 2,
    alignItems: 'stretch',
    top: '19vh',
    right: '20vw',
    left: '20vw',
    width: '250px',
    height: '25vh',
    '& img': {
      width: '100%',
    },
  },
  body: {
    position: 'relative',
    width: '100vw',
    textAlign: 'center',
  },
  text: {
    fontSize: '1.25em',
  },
  text2: {
    fontSize: '1em',
    textAlign: 'center',
    paddingLeft: '15vw',
    paddingRight: '15vw',
    '& div': {
      marginBottom: '5px',
    },
  },
  actionContainer: {
    display: 'flex',
    opacity: '1 !important',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  actionBody: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    width: '90vw',
  },
  flexColumnFullHeight: {
    height: '100vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  actionScreenAbsoluteIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '3.5em',
  },
  selectDeviceFormContainer: {
    paddingTop: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  referenceIdText: {
    fontSize: '14px',
  },
  infoPrompt: {
    fontWeight: 'bold',
    paddingBottom: '0.7rem',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.7rem',
  },
  marginBottomNone: {
    marginBottom: 0,
  },
}));
