export type ErrorType = {
  code: number;
  errorCode?: string; // optional field to match with new FVError type
  type: string;
  message: string;
  details: string;
  retry?: boolean;
  isFatal?: boolean;
};

export const GENERIC_ERROR: ErrorType = {
  message: 'errorGenericMessage',
  details: 'errorGenericDetails',
  // Code should not matter
  code: 0,
  type: 'GENERIC_ERROR',
  isFatal: true,
};

export const UNAUTHORIZED_ERROR: ErrorType = {
  message: 'error401Message',
  details: 'error401Details',
  code: 401,
  type: 'UNAUTHORIZED_ERROR',
  isFatal: true,
};

export const NETWORK_ERROR: ErrorType = {
  code: 40000,
  type: 'NETWORK_ERROR',
  message: 'error40000Message',
  details: '',
  // needed for special case when using web app
  retry: true,
};

// The catch all error
export const FINVERSE_OAUTH_ERROR: ErrorType = {
  code: 40001,
  type: 'FINVERSE_OAUTH_ERROR',
  message: 'error40001Message',
  details: 'error40001Details',
  isFatal: true,
};

export const OAUTH_CODE_ERROR: ErrorType = {
  code: 40002,
  type: 'OAUTH_CODE_ERROR',
  message: 'OAuthErrorMessage',
  details: 'OAuthErrorDetails',
};

export const OAUTH_STATE_ERROR: ErrorType = {
  code: 40003,
  type: 'OAUTH_STATE_ERROR',
  message: 'OAuthErrorMessage',
  details: 'OAuthErrorDetails',
};

export const OAUTH_JWK_ERROR: ErrorType = {
  code: 40004,
  type: 'OAUTH_JWK_ERROR',
  message: 'OAuthErrorMessage',
  details: 'OAuthErrorDetails',
};

export const FRONTEND_CONNECTION_ERROR: ErrorType = {
  code: 40005,
  type: 'FRONTEND_CONNECTION_ERROR',
  message: 'error40005Message',
  details: 'error40005Details',
  isFatal: true,
};

export const OAUTH_CALLBACK_ERROR: ErrorType = {
  code: 40006,
  type: 'OAUTH_CALLBACK_ERROR',
  message: 'linkErrorMessage',
  details: 'linkErrorDetails',
};

export const REAL_BANK_CONNECTIONS_LIMIT: ErrorType = {
  code: 40009,
  errorCode: 'CONNECTION_LIMIT',
  type: 'REAL_BANK_CONNECTIONS_LIMIT',
  message: 'error40009Message',
  details: 'error40009Details',
};

export const PAYMENT_INSTRUCTION_USED: ErrorType = {
  code: 40010,
  isFatal: true,
  type: 'PAYMENT_INSTRUCTION_USED',
  message: 'error40010Message',
  details: 'error40010Details',
};

export const CLOSE_LINK_UI_ERROR: ErrorType = {
  // Should not matter
  code: 0,
  type: 'CLOSE_LINK_UI_ERROR',
  message: 'closeLinkUIError',
  // Currently this error does not have details associated
  details: '',
};

export const PAYMENT_TEMPORARY_ERROR: ErrorType = {
  code: 0, // deprecated
  type: 'PAYMENT_TEMPORARY_ERROR',
  message: 'paymentTemporaryErrorMessage',
  details: 'paymentTemporaryErrorDetails',
};

export const PAYMENT_DISABLED: ErrorType = {
  code: 0, // deprecated,
  type: 'PAYMENT_DISABLED',
  message: 'paymentDisabledMessage',
  details: 'paymentDisabledDetails',
};

export const PAYMENT_CURRENCY_UNSUPPORTED: ErrorType = {
  code: 0, // deprecated,
  type: 'PAYMENT_CURRENCY_UNSUPPORTED',
  message: 'paymentCurrencyUnsupportedMessage',
  details: 'paymentCurrencyUnsupportedDetails',
};
