export const enum Product {
  unknown = 'UNKNOWN',
  accounts = 'ACCOUNTS',
  onlineTransactions = 'ONLINE_TRANSACTIONS',
  statements = 'STATEMENTS',
  historicalTransactions = 'HISTORICAL_TRANSACTIONS',
  accountNumber = 'ACCOUNT_NUMBER',
  identity = 'IDENTITY',
  balanceHistory = 'BALANCE_HISTORY',
  payments = 'PAYMENTS',
}
