import { memo } from 'react';

import { TableContainer, Paper, TableRow, TableCell, TableBody, Table as MuiTable } from '@mui/material';

import { LinkStatusMessage, LinkStatusMessageType } from '../../entities/api';

interface ConfirmationTableProps {
  messages?: LinkStatusMessage[];
}

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export const TextTable = memo(function TextTableComponent(props: ConfirmationTableProps) {
  const { messages = [] } = props;
  const rows = messages.filter((msg) => {
    if (msg.type === LinkStatusMessageType.TABLE_ROW) {
      return msg;
    }
    return false;
  });

  //  Skip rendering if there is no row in msg
  if (!rows.length) {
    return null;
  }

  return (
    <TableContainer component={Paper} elevation={0} sx={{ overflowY: 'auto', backgroundColor: 'background.default' }}>
      <MuiTable aria-label="table-data" size="small" sx={{ backgroundColor: '#ffffff' }}>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ paddingX: 1 }}>
                {capitalizeFirstLetter(row.name) + ':'}
              </TableCell>
              <TableCell align="left" sx={{ wordBreak: 'break-all', paddingX: 1 }}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
});
