import { useCallback, useEffect, useState } from 'react';

import { Typography, SxProps, Theme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LineItem } from '../../entities';
import useAPIClient from '../../hooks/useClient';
import useSearchParams from '../../hooks/useSearchParams';

type SingleLineItemProps = {
  item: LineItem;
  sx: SxProps<Theme>;
};

const SingleLineItem = ({ item, sx }: SingleLineItemProps) => {
  const { t } = useTranslation();

  const fontSize = item.item_type === 'TOTAL' ? 20 : 16;
  const fontWeight = item.item_type === 'TOTAL' ? 'bold' : '';

  return (
    <Grid container item direction="row" spacing={1}>
      <Grid item xs>
        <Typography variant="body2" fontWeight={fontWeight} sx={sx} fontSize={fontSize}>
          {t(item.item_type)}
        </Typography>
      </Grid>
      <Grid item textAlign="end">
        <Typography variant="body2" fontWeight={fontWeight} sx={sx} fontSize={fontSize}>
          {item.currency} {item.amount}
        </Typography>
      </Grid>
    </Grid>
  );
};

type LineItemsDisplayProps = {
  paymentType: string;
  sx: SxProps<Theme>;
};

export const LineItemsDisplay = ({ paymentType, sx }: LineItemsDisplayProps) => {
  const client = useAPIClient();
  const { params } = useSearchParams();
  const [lineItems, setLineItems] = useState<LineItem[]>([]);

  const getLineItems = useCallback(
    async (token: string) => {
      try {
        const lineItemsResp = await client.getLineItemsForDisplay(token, paymentType);

        // if line items only contains AMOUNT_DUE and TOTAL then we should only show the TOTAL, else show all
        if (lineItemsResp.line_items.every((item) => item.item_type === 'AMOUNT_DUE' || item.item_type === 'TOTAL')) {
          setLineItems(lineItemsResp.line_items.filter((item) => item.item_type === 'TOTAL'));
        } else {
          setLineItems(lineItemsResp.line_items);
        }
      } catch (err) {
        // can keep going
      }
    },
    [client, paymentType],
  );

  useEffect(() => {
    getLineItems(params.token);
  }, [getLineItems, params.token]);

  return (
    <Grid container direction="column">
      {lineItems.map((i, index) => (
        <SingleLineItem item={i} key={`${i.item_type}-${index}`} sx={sx} />
      ))}
    </Grid>
  );
};
