import { MandateAuthorizationScreens } from '../../constants/onboarding';
import { ActionRequiredData, LinkStatusMessageType, LinkStatusType } from '../../entities';

export enum ActionName {
  checkStatus = 'CHECK_STATUS',
}

export const LoadingScreenData: ActionRequiredData = {
  id: '',
  type: LinkStatusType.INFO,
  name: 'CHECK_STATUS',
  messages: [
    {
      name: 'messages_loading_title',
      type: LinkStatusMessageType.TITLE,
      value: 'Connecting...',
    },
    {
      name: 'messages_thank_patience',
      type: LinkStatusMessageType.BODY,
      value: 'Thank you for your patience!',
    },
    {
      name: 'messages_process_time_info_default',
      type: LinkStatusMessageType.BODY,
      value: 'The process could take up to 1 minute.',
    },
    {
      name: 'messages_donot_login_to_institution',
      type: LinkStatusMessageType.BODY,
      value:
        // prettier-ignore
        'Please do not use your institution\'s mobile app or online portal to login at the same time, since this may interrupt our connection.',
    },
  ],
  fields: [],
  buttons: [],
};

export const MandateAuthorizationLoadingScreenData: ActionRequiredData = {
  id: '',
  type: LinkStatusType.INFO,
  name: MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CHECK_STATUS,
  messages: [
    {
      name: 'messages_loading_title',
      type: LinkStatusMessageType.TITLE,
      value: 'Connecting...',
    },
    {
      name: 'messages_thank_patience',
      type: LinkStatusMessageType.BODY,
      value: 'Thank you for your patience!',
    },
    {
      name: 'messages_process_time_info_default',
      type: LinkStatusMessageType.BODY,
      value: 'The process could take up to 1 minute.',
    },
  ],
  fields: [],
  buttons: [],
};
