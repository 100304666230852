import { useContext, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { LoadingScreenData } from '../../constants/linkStatusData/linkStatusData';
import { LoginIdentityContext } from '../../contexts/LoginIdentityContext';
import useSearchParams from '../../hooks/useSearchParams';
import { OnboardingRoutes } from '../../routers/routes';
import { decodeToken } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';

//
// this is a pure redirect page; we don't expect to have any FE component here.
export default function RefreshLandingScreen(): JSX.Element {
  const { params, searchParamsString } = useSearchParams();
  const decodedToken = decodeToken(params.token);
  const { setLoginIdentityId } = useContext(LoginIdentityContext);

  setLoginIdentityId(decodedToken.loginIdentityId);

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.DATA_REFRESH_LANDING_SCREEN, eventProperties);
  });

  return (
    <Redirect
      to={{
        pathname: OnboardingRoutes.ActionReq(decodedToken.institutionId),
        search: `${searchParamsString}&linkStatusId=${decodedToken.loginIdentityId}`,
        state: {
          linkStatusData: LoadingScreenData,
        },
      }}
    />
  );
}
