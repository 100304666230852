// This file contains the various routes used in Finverse link. Routes are divided by the 3 kinds of flows -
// Onboarding (anything to do before the flow starts), Data (to do with data-related flows), Mandate/FPS (to do with payments-related flows)

// Currently all non-payment link pages are under the onboarding flow. In the future we will move this to
const OnboardingBase = '/onboarding';
export const OnboardingRoutes = {
  Base: OnboardingBase,
  Consent: OnboardingBase,
  Institutions: `${OnboardingBase}/institutions`,
  Login: (institutionId: string) => `${OnboardingBase}/login/${institutionId}`,
  Message: `${OnboardingBase}/message`,
  ActionReq: (institutionId: string) => `${OnboardingBase}/action/${institutionId}`,
  SelectPaymentType: `${OnboardingBase}/select-payment-type`,
  SelectSenderType: `${OnboardingBase}/select-sender-type`,
  Refresh: `${OnboardingBase}/refresh`,
};

const MandatesBase = '/mandates';
export const MandateRoutes = {
  Base: MandatesBase,
  PaymentLinkError: `${MandatesBase}/error`,
  PaymentConfirm: `${MandatesBase}/payment-confirm`,
  PaymentPoll: `${MandatesBase}/payment-poll`,
  PaymentSubmitted: `${MandatesBase}/payment-submitted`,
  AutopayConsent: `${MandatesBase}/autopay-consent`,
  SubmittedMessage: `${MandatesBase}/request-submitted`,
  SubmissionFailedMessage: `${MandatesBase}/submission-failed`,
  MandateAuthChecklistRedirect: `${MandatesBase}/authchecklist-redirect`,
};

const FpsBase = '/fps';
export const FpsRoutes = {
  Base: FpsBase,
  SelectAccHolder: `${FpsBase}/select-account-holder`,
  PaymentConfirm: `${FpsBase}/payment-confirm`,
  PaymentSubmitted: `${FpsBase}/payment-submitted`,
};

const CardBase = '/card';
export const CardRoutes = {
  Base: CardBase,
  SuccessCreditCard: `${CardBase}/success-credit-card`,
};

export const GenericErrorRoutes = {
  Base: '/error',
  ApiError: '/error/api',
};

const PaymentBase = '/payment';
export const PaymentRoutes = {
  Base: PaymentBase,
  GenericSuccess: `${PaymentBase}/success`,
};

const PaymentMethodBase = '/payment_method';
export const PaymentMethodRoutes = {
  Base: PaymentMethodBase,
  SetupSuccess: `${PaymentMethodBase}/setup-success`,
};

export const CybersourceRedirectRoute = '/cybersource-redirect';
