import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export type ISearchParams = {
  token: string;
  uiMode: string;
  [key: string]: string;
};

export default function useSearchParams(): {
  params: ISearchParams;
  searchParamsString: string;
} {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const generatedParams: ISearchParams = useMemo(() => {
    const params = {
      ...Object.fromEntries(searchParams),
      token: searchParams.get('token') || '',
      uiMode: searchParams.get('ui_mode') || '',
    };

    return params;
  }, [searchParams]);

  return {
    params: generatedParams,
    searchParamsString: searchParams.toString(),
  };
}
