import { createContext, ReactElement, ReactNode } from 'react';

import config from '../config';
import { APIClient, getApiClient } from '../services/api';

export const ClientContext = createContext<APIClient>({} as APIClient);

export const ClientProvider = ({ children }: { children: ReactNode }): ReactElement => (
  <ClientContext.Provider value={getApiClient(config.apiHost, config.oauthHost, config.paymentApiHost)}>
    {children}
  </ClientContext.Provider>
);
