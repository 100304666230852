import { ErrorType, GENERIC_ERROR, UNAUTHORIZED_ERROR } from '../entities/error';
import LinkError from '../entities/LinkError';
import { isLinkError } from './runtimeType';

const fvErrorMap: Record<string, ErrorType> = {
  'Request failed with status code 401': UNAUTHORIZED_ERROR,
};

export function translateFvError(error: Error): LinkError {
  // Error is constructed with translated error messages
  if (isLinkError(error)) return error;

  let errorType = fvErrorMap[error.message];
  if (errorType === undefined) {
    errorType = GENERIC_ERROR;
    if (error.name !== 'Error') {
      // If the error is thrown by the browser
      errorType.message = error.message;
      errorType.details = '';
    }
  }

  return new LinkError(errorType);
}
