import { Dispatch, SetStateAction, useMemo, useState, createContext, ReactNode } from 'react';

interface ILoginIdentityContext {
  loginIdentityId: string;
  setLoginIdentityId: Dispatch<SetStateAction<string>>;
}

export const LoginIdentityContext = createContext<ILoginIdentityContext>({
  loginIdentityId: '',
  setLoginIdentityId: () => {
    null;
  },
});

export const LoginIdentityProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [loginIdentityId, setLoginIdentityId] = useState<string>('');

  const contextValue = useMemo(
    () => ({
      loginIdentityId,
      setLoginIdentityId,
    }),
    [loginIdentityId, setLoginIdentityId],
  );

  return <LoginIdentityContext.Provider value={contextValue}>{children}</LoginIdentityContext.Provider>;
};
