type AppConfig = {
  env: 'dev2' | 'prod' | 'local';
  apiHost: string;
  paymentApiHost: string;
  clientId: string;
  oauthHost: string;
  oauthScope: string;
  redirectUri: string;
  grantType: string;
  amplitudeApiKey: string;
};

export const supportedLanguage: Record<string, string> = {
  en: 'English',
  zh: '中文',
  vi: 'Tiếng Việt',
};

function getConfig(): AppConfig {
  const host = window.location.hostname;
  const env = getEnv(host);

  if (env === 'dev2') {
    return {
      env,
      apiHost: 'https://api.dev2.finverse.net',
      paymentApiHost: 'https://pay.dev2.finverse.net',
      clientId: 'i do not care',
      oauthHost: 'https://woauth.dev2.finverse.net',
      oauthScope: 'test',
      redirectUri: 'https://demo-api.dev2.finverse.net/callback',
      grantType: 'authorization_code',
      amplitudeApiKey: 'd25b0f6f8964cc8a13797396c90db9e1',
    };
  }

  if (env === 'prod') {
    return {
      env,
      apiHost: 'https://api.prod.finverse.net',
      paymentApiHost: 'https://pay.prod.finverse.net',
      clientId: 'i do not know yet',
      oauthHost: 'https://woauth.prod.finverse.net',
      oauthScope: 'test',
      redirectUri: 'https://demo-api.prod.finverse.net/callback',
      grantType: 'authorization_code',
      amplitudeApiKey: 'ead0d78ce869b0f059dc5915166be8e9',
    };
  }

  return {
    env,
    apiHost: 'https://api.dev2.finverse.net',
    paymentApiHost: 'https://pay.dev2.finverse.net',
    clientId: '01EV83FA53G0445ED21PNBB83S',
    oauthHost: 'https://woauth.dev2.finverse.net',
    oauthScope: 'test',
    redirectUri: 'https://demo-api.dev2.finverse.net/callback',
    grantType: 'authorization_code',
    amplitudeApiKey: 'd25b0f6f8964cc8a13797396c90db9e1', // same as dev2
  };
}

export function getEnv(url: string) {
  if (url.includes('local')) return 'local';
  if (url.includes('dev2')) return 'dev2';
  if (url.includes('prod')) return 'prod';

  return 'prod';
}

export default getConfig();
