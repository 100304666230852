import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import config from '../config';
import { MandateAuthorizationLoadingScreenData } from '../constants/linkStatusData/linkStatusData';
import { MandateAuthorizationScreens, MandateStatus } from '../constants/onboarding';
import { MandateInfoContext } from '../contexts/MandateInfoContext';
import { GENERIC_ERROR, Institution, LinkStatusMessage, LinkStatusMessageType } from '../entities';
import { MandateAuthResponse } from '../entities/api/mandate';
import { MandateAuthToken } from '../entities/api/token';
import LinkError from '../entities/LinkError';
import {
  getErrorPath,
  getPaymentLinkErrorPath,
  getSuccessPath,
  parseGetMandateAuthLinkRequest,
  parseToken,
} from '../pages/common/utils';
import { GenericErrorRoutes, MandateRoutes } from '../routers/routes';
import { getProductFlow } from '../services';
import amplitude from '../services/amplitude';
import { encryptData } from '../services/crypto';
import { parseAuthChecklist, snakeCaseToTitleCase } from '../utils/authChecklistToComponentJSON';
import { translateErrorDetails } from '../utils/translate';
import { addQueryParamsToUrl } from '../utils/url';
import useActionRequiredPages from './useActionRequiredPages';
import useAPIClient from './useClient';
import useRedirectURI from './useRedirectURI';
import useSearchParams from './useSearchParams';

export default function useMandateAuthorizationHelper() {
  const jwksUrl = `${config.apiHost}/payments/jwks`;
  const client = useAPIClient();
  const history = useHistory();
  const { actionData, setActionData, pages, setPages } = useActionRequiredPages();
  // state params
  const { institutionId: urlPathInstitutionId } = useParams<{ institutionId?: string }>();
  const { setMandateId, institutionInfo, setInstitutionInfo } = useContext(MandateInfoContext);
  const {
    params: { token, mandate_id, uiMode },
    searchParamsString,
  } = useSearchParams();
  const { t } = useTranslation();
  const productFlow = getProductFlow(token);

  const { setRedirectURI } = useRedirectURI();

  const mandateToken = parseToken(token) as MandateAuthToken;
  const mandateAuthLinkRequest = parseGetMandateAuthLinkRequest(mandateToken.getMandateAuthLinkRequest);

  if (mandateAuthLinkRequest.link_customizations?.redirect_uri !== undefined) {
    const redirectWithParam = addQueryParamsToUrl(
      mandateAuthLinkRequest.link_customizations.redirect_uri,
      new URLSearchParams({ mandate_id: mandate_id }),
    );
    setRedirectURI(redirectWithParam);
  }

  const handleMandateAuthFlow = async (institution: Institution, senderType: string): Promise<void> => {
    const institutionId = institution.institution_id;
    try {
      setMandateId(mandate_id);
      setInstitutionInfo({
        bankCode: institution.payment_info.other_info.bank_code,
        institutionName: institution.institution_name,
      });
      // Update mandate institution id and sender type
      const updateMandateInstitutionResp = await client.updateMandateInstitutionSelection(
        token,
        institutionId,
        senderType,
      );
      if (updateMandateInstitutionResp === null) {
        throw new Error();
      }

      await redirectToMandateAuthScreen(institutionId);
    } catch (err) {
      history.push(
        getErrorPath({
          institutionId: institutionId,
          error: new LinkError(GENERIC_ERROR),
          searchParamsString,
          destination: '/onboarding/institutions',
        }),
      );
    }
  };

  const redirectToMandateAuthScreen = async (institutionId: string): Promise<void> => {
    // Make API call to get mandate auth list
    const mandateAuthResp = await client.getMandateAuthList(token);
    if (mandateAuthResp === null) {
      throw new Error();
    }

    // look for auth checklist that is redirect url
    const redirectItem = mandateAuthResp.auth_checklist.find((prop) => {
      return prop.options[0].name === 'REDIRECT_URL' && prop.options[0].redirect_url !== '';
    });

    if (redirectItem !== undefined) {
      if (mandateAuthResp.auth_checklist.length > 1) {
        // if checklist has redirect but also contains other checklist item, return error
        return history.push({
          pathname: GenericErrorRoutes.Base,
          search: searchParamsString,
        });
      }
      // if it contains redirect, go to redirect page
      const newSearchParams = new URLSearchParams(searchParamsString);
      newSearchParams.set(
        'auth_checklist_redirect_url',
        mandateAuthResp.auth_checklist[0].options[0].redirect_url ?? '',
      );
      newSearchParams.set('institution_id', mandateAuthResp.institution_id);
      return history.push({
        pathname: MandateRoutes.MandateAuthChecklistRedirect,
        search: newSearchParams.toString(),
      });
    }

    // Parse mandate auth list to an array of action required
    const consentData = mandateDetailsToConsentData(mandateAuthResp);
    const parsedAuthMandateScreenData = parseAuthChecklist(
      t,
      mandateAuthResp.auth_checklist,
      productFlow,
      mandateAuthResp.recipient.name,
      consentData,
    );

    // We use the latest page as the current page and save the other pages
    const linkStatusData = parsedAuthMandateScreenData.pop();
    setPages(parsedAuthMandateScreenData);

    return history.push({
      pathname: `/onboarding/action/${institutionId}`,
      search: searchParamsString,
      state: { linkStatusData },
    });
  };

  const addUserInputToMessages = (
    linkStatusMessage: LinkStatusMessage[],
    inputData: Record<string, unknown>,
  ): LinkStatusMessage[] => {
    const formattedInputData = Object.entries(inputData).map(([name, value]) => {
      switch (name) {
        case 'ACCOUNT_NUMBER':
          return [
            t('Pay from'),
            institutionInfo.institutionName !== '' && institutionInfo.bankCode !== ''
              ? `${t(institutionInfo.institutionName)} (${institutionInfo.bankCode}) // ${value}`
              : value,
          ];
        case 'ACCOUNTHOLDER_NAME':
          return [t('Accountholder'), value];
        case 'HK_ID':
          return [t('ID Number'), `${t('HK ID')} ${value}`];
        case 'PASSPORT':
          return [t('ID Number'), `${t('Passport')} ${value}`];
        case 'HK_BUSINESS_REGISTRATION':
          return [t('ID Number'), `${t('Business Registration')} ${value}`];
        case 'HK_CERTIFICATE_OF_INCORPORATION':
          return [t('ID Number'), `${t('Certificate of Incorporation')} ${value}`];
        default:
          return [t(snakeCaseToTitleCase(name)), value];
      }
    });
    const newLinkStatusMessage = [...linkStatusMessage];
    formattedInputData.forEach(([name, value]) =>
      newLinkStatusMessage.push({
        type: LinkStatusMessageType.TABLE_ROW,
        name: name as string,
        value: typeof value === 'string' ? value : '',
      }),
    );
    const sortOrder = [
      t('Account Holder'),
      t('ID Number'),
      t('Pay from'),
      t('Pay to'),
      t('Currency'),
      t('Start Date'),
      t('End Date'),
      t('Description'),
    ];

    // this sorts the link message in the custom order defined as above
    return newLinkStatusMessage.sort(function (a, b) {
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
    });
  };

  const submit = async (data: Record<string, unknown>): Promise<void> => {
    try {
      if (typeof urlPathInstitutionId !== 'string') {
        throw new Error();
      }

      // Create a new data object of the data to be stored or submitted
      const newData = { ...actionData, ...data };

      if (pages.length > 0) {
        // We have pages that have not been complete
        // Thus, get the latest page and route to it
        const pagesCopy = [...pages];
        const linkStatusData = pagesCopy.pop();
        setPages(pagesCopy);
        setActionData(newData);

        // This updates the confirm mandate page messages field with the user's input
        if (linkStatusData?.name === MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CONSENT) {
          linkStatusData.messages = addUserInputToMessages(linkStatusData.messages, newData);
        }

        return history.push({
          pathname: `/onboarding/action/${urlPathInstitutionId}`,
          search: searchParamsString,
          state: { linkStatusData },
        });
      }

      const items = Object.entries(newData).map(([key, value]) => ({
        name: key,
        value,
      }));
      // We have no more pages and need to submit the data
      // Encrypt data
      const encryptedData = await encryptData(client, jwksUrl, JSON.stringify({ items }));
      const finalPayload = { ...encryptedData };

      // Parse mandate auth list to an array of action required
      // Post the data to the post mandate auth list API
      const mandateAuthResp = await client.postMandateAuthList(token, finalPayload);
      if (!mandateAuthResp) {
        throw new Error();
      }

      if (mandateAuthResp.mandate_status === MandateStatus.AUTHORIZATION_REQUIRED) {
        // We have further check list items that need input
        // Can optimise later to make the call only when consent data is in auth checklist
        const mandateDetails = await client.getMandateAuthList(token);
        if (mandateDetails === null) {
          throw new Error();
        }

        // Parse the auth checklist and route to latest page
        // Store the other pages and reset action data
        const consentData = mandateDetailsToConsentData(mandateDetails);
        const parsedAuthMandateScreenData = parseAuthChecklist(
          t,
          mandateAuthResp.auth_checklist,
          productFlow,
          mandateAuthResp.recipient.name,
          consentData,
        );
        const linkStatusData = parsedAuthMandateScreenData.pop();
        setPages(parsedAuthMandateScreenData);
        setActionData({});

        return history.push({
          pathname: `/onboarding/action/${urlPathInstitutionId}`,
          search: searchParamsString,
          state: { linkStatusData },
        });
      }

      if (
        mandateAuthResp.mandate_status === MandateStatus.PROCESSING ||
        mandateAuthResp.mandate_status === MandateStatus.SUBMITTED ||
        mandateAuthResp.mandate_status === MandateStatus.FAILED
      ) {
        return history.push({
          pathname: `/onboarding/action/${urlPathInstitutionId}`,
          search: searchParamsString,
          state: { linkStatusData: MandateAuthorizationLoadingScreenData },
        });
      }

      // should not reach here
      throw new Error();
    } catch (error) {
      history.push(
        getErrorPath({
          institutionId: urlPathInstitutionId ?? 'testbank',
          error: new LinkError(GENERIC_ERROR),
          searchParamsString,
          destination: '/onboarding/institutions',
        }),
      );
    }
  };

  const pollMandate = async () => {
    try {
      if (typeof urlPathInstitutionId !== 'string') {
        throw new Error();
      }

      let mandateAuthResp: MandateAuthResponse | null = null;
      for (let i = 0; i < 90; i++) {
        const finalStatus = [MandateStatus.SUCCEEDED, MandateStatus.FAILED];
        if (i >= 20) {
          // after 20 seconds of polling, we will accept SUBMITTED as well
          finalStatus.push(MandateStatus.SUBMITTED);
        }
        const pollResp = await client.getMandateAuthList(token);
        if (pollResp === null) {
          throw new Error();
        }
        if (finalStatus.includes(pollResp.mandate_status)) {
          mandateAuthResp = pollResp;
          break;
        }
        await new Promise((r) => setTimeout(r, 1000));
      }

      if (mandateAuthResp == null) {
        throw new Error();
      }

      if (mandateAuthResp.mandate_status === MandateStatus.SUCCEEDED) {
        return history.push(
          getSuccessPath({
            institutionId: urlPathInstitutionId,
            header: 'mandateSucceededHeader',
            body: 'mandateAuthChecklistSubmittedText', // text to be same as submitted scenario
            searchParamsString,
          }),
        );
      } else if (mandateAuthResp.mandate_status === MandateStatus.SUBMITTED) {
        // The auth checklist has been submitted successfully navigate to success screen
        return history.push(
          getSuccessPath({
            institutionId: urlPathInstitutionId,
            header: 'mandateAuthChecklistSubmittedHeader',
            body: 'mandateAuthChecklistSubmittedText',
            searchParamsString,
          }),
        );
      } else if (mandateAuthResp.mandate_status === MandateStatus.FAILED) {
        // The auth checklist has some error navigate to error screen

        // Record API error
        amplitude.trackApiError(
          mandateAuthResp.error.type,
          mandateAuthResp.error.message,
          mandateAuthResp.error.details,
        );
        return history.push(
          getPaymentLinkErrorPath({
            institutionId: urlPathInstitutionId,
            header: mandateAuthResp.error.message,
            body: translateErrorDetails(t, mandateAuthResp.error.details),
            searchParamsString,
          }),
        );
      }
    } catch (err) {
      history.push(
        getErrorPath({
          institutionId: urlPathInstitutionId ?? 'testbank',
          error: new LinkError(GENERIC_ERROR),
          searchParamsString,
          destination: '/onboarding/institutions',
        }),
      );
    }
  };

  const mandateDetailsToConsentData = (mandateDetails: MandateAuthResponse): { name: string; value: string }[] => {
    const consentData = [];
    if (mandateDetails.recipient.name) {
      consentData.push({ name: t('Pay to'), value: mandateDetails.recipient.name });
    }
    if (mandateDetails.mandate_details.payment_schedule?.amount) {
      consentData.push({
        name: t('Amount'),
        value: mandateDetails.mandate_details.payment_schedule?.amount.toString(),
      });
    }
    if (mandateDetails.mandate_details.currency) {
      consentData.push({ name: t('Currency'), value: mandateDetails.mandate_details.currency });
    }
    if (mandateDetails.mandate_details.payment_schedule?.frequency) {
      consentData.push({
        name: t('Frequency'),
        value: mandateDetails.mandate_details.payment_schedule.frequency,
      });
    }
    if (mandateDetails.mandate_details.start_date) {
      consentData.push({ name: t('Start Date'), value: mandateDetails.mandate_details.start_date });
    }
    if (mandateDetails.mandate_details.end_date) {
      consentData.push({ name: t('End Date'), value: mandateDetails.mandate_details.end_date });
    }
    if (mandateDetails.mandate_details.description) {
      consentData.push({ name: t('Description'), value: mandateDetails.mandate_details.description });
    }
    return consentData;
  };

  return { submit, handleMandateAuthFlow, redirectToMandateAuthScreen, pollMandate };
}
