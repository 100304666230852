import { useMemo } from 'react';

import { Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Logo from './LinkLogo';

// NOTE: This is for the future when we have privacy pages for different languages
const i18nAvailablePrivacyLanguages: string[] = ['vi', 'zh'];
const basePrivacyUrl = 'https://www.finverse.com/legal';

const ConsentLogo = () => (
  <Logo
    src={'/img/logo.svg'}
    alt={'Finverse'}
    sx={{ maxHeight: '100px', marginBottom: 0, width: 'auto', height: '24px' }}
  />
);

export const ConsentDisclaimer = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const privacyRef = useMemo(() => {
    if (i18nAvailablePrivacyLanguages.includes(i18n.language)) {
      return `${basePrivacyUrl}/${i18n.language}`;
    }
    return basePrivacyUrl;
  }, [i18n.language]);

  return (
    <>
      <div
        style={{
          whiteSpace: 'nowrap',
          display: 'inline-flex',
          justifyContent: 'center',
          marginTop: '4px',
          marginBottom: '-12px',
        }}
      >
        {t('Powered by')}:
        <ConsentLogo />
      </div>

      <Grid container style={{ marginBottom: '12px' }} direction="column">
        <Grid item marginBottom="-4px">
          <Typography variant="subtitle2">{t('consentFinverseAgreeTermsText')}</Typography>
        </Grid>
        <Grid item>
          <Link variant="subtitle2" target="_blanks" color="primary" href={privacyRef}>
            {t('consentFinverseAgreeTermsLink')}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
