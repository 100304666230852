import React from 'react';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import ReactDOM from 'react-dom';

import App from './App';
import { RedirectURIProvider } from './contexts/RedirectURIContext';
import reportWebVitals from './reportWebVitals';
import { getTheme } from './services';
import './services/i18n';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const { worker } = require('./mocks/msw/browser');
  // worker.start();
}

const theme = createTheme(getTheme());

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RedirectURIProvider>
            <App />
          </RedirectURIProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
