import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import toLowerFirstCase from '../utils/toLowerFirstCase';
import { TopContainer } from './Containers';
import Logo from './LinkLogo';

interface HeaderProps {
  /**
   * Source to image, this override loading institution icon
   **/
  src?: string;
  /**
   * Alt to image, this override loading institution icon
   **/
  alt?: string;
  /**
   * Institution id of a source
   **/
  institutionId?: string;
  /**
   * Variant to display logo
   **/
  variant?: string;

  /**
   * Translated message display as title
   **/
  message: string;
}

export default function LinkHeader(props: HeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { message, variant } = props;

  const src = props.src ? props.src : mapLogoSrc(props.institutionId);
  const alt = props.alt ? props.alt : props.institutionId;

  return (
    <TopContainer
      sx={{
        gap: 2,
        paddingBottom: 0,
      }}
    >
      <Logo
        src={src}
        alt={alt}
        variant={variant}
        /* customer logo is around 100px height */
        sx={{ maxHeight: checkLogoExist(props.institutionId) ? '100px' : '72px', marginBottom: 0 }}
      />
      <Typography variant="h5" component="h5">
        {t(toLowerFirstCase(message), message)}
      </Typography>
    </TopContainer>
  );
}

function mapLogoSrc(institutionId = ''): string {
  if (checkLogoExist(institutionId)) {
    return `/img/institutions/${institutionToLogoMap[institutionId]}.svg`;
  }
  return '/img/institutions/default.svg';
}

function checkLogoExist(institutionId = ''): boolean {
  if (institutionToLogoMap[institutionId]) {
    return true;
  }
  return false;
}

export const institutionToLogoMap: Record<string, string> = {
  acb: 'acb',
  bca: 'bca',
  'bdoph-business': 'bdoph-business',
  bdoph: 'bdoph',
  'beahk-business': 'beahk-business',
  'beahk-payments': 'beahk-payments',
  beahk: 'beahk',
  bidv: 'bidv',
  'bochk-business': 'bochk-business',
  'bochk-payments': 'bochk',
  'boc-sg-payments': 'bochk',
  bochk: 'bochk',
  bpi: 'bpi',
  'bri-id': 'bri-id',
  'cimb-my': 'cimb-my',
  'citi-hk-payments': 'citi-hk',
  'citi-hk': 'citi-hk',
  'citi-sg': 'citi-hk',
  'dbs-business': 'dbs-business',
  'dbs-hk': 'dbs-hk',
  'dbs-hk-payments': 'dbs-hk',
  'dbs-sg-payments': 'dbs-hk',
  'dbs-sg': 'dbs-sg',
  default: 'default',
  finverse: 'finverse',
  gcash: 'gcash',
  'gojek-id': 'gojek-id',
  gojek: 'gojek',
  'hangseng-business': 'hangseng',
  'hangseng-payments': 'hangseng',
  hangseng: 'hangseng',
  'hsbc-hk-business': 'hsbc-hk-business',
  'hsbc-hk-payments': 'hsbc-hk-business',
  'hsbc-hk': 'hsbc-hk',
  'hsbc-sg-payments': 'hsbc-sg-payments',
  hsbcnet: 'hsbcnet',
  'icbc-sg-payments': 'icbc',
  'icbc-hk': 'icbc',
  landbank: 'landbank',
  maya: 'maya',
  'maybank-my': 'maybank-my',
  'maybank-sg-payments': 'maybank-sg-payments',
  'metrobank-web': 'metrobank-web',
  metrobank: 'metrobank',
  'ocbc-business-my': 'ocbc-business-my',
  'ocbc-business-sg': 'ocbc-business-sg',
  'ocbc-sg-payments': 'ocbc-sg-payments',
  ocbc: 'ocbc',
  ofbank: 'ofbank',
  ovo: 'ovo',
  pnb: 'pnb',
  'publicbank-my': 'publicbank-my',
  rcbc: 'rcbc',
  scb: 'scb',
  'security-bank': 'security-bank',
  shb: 'shb',
  'standardchartered-business': 'standardchartered-business',
  'standardchartered-hk-payments': 'standardchartered-hk-personal',
  'standardchartered-hk-personal': 'standardchartered-hk-personal',
  'standardchartered-sg-payments': 'standardchartered-hk-personal',
  techcombank: 'techcombank',
  techcombank2: 'techcombank2',
  testbank: 'testbank',
  'testbank-dbs-hk': 'testbank',
  'unionbank-ph': 'unionbank-ph',
  'uob-sg-business': 'uob-sg-business',
  'uob-sg-payments': 'uob-sg-business',
  'uob-sg': 'uob-sg',
  vietcombank: 'vietcombank',
  vietinbank: 'vietinbank',
  vpbank2: 'vpbank2',
};
