import { ComponentProps } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, TextField } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

import useToggle from '../hooks/useToggle';

type Props = {
  id: string;
  style: string;
  label: string;
  autoFocus: boolean;
  name: string;
  register: (name: never, options?: never) => UseFormRegisterReturn;
} & ComponentProps<typeof TextField>;

export default function LinkPassword(props: Props): JSX.Element {
  const { id, name, style, label, autoFocus, register, ...rest } = props;
  const [showPassword, toggleShow] = useToggle(false);

  return (
    <TextField
      {...register(name as never, { required: `${label} is required` } as never)}
      id={id}
      name={name}
      label={label}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      className={style}
      autoFocus={autoFocus}
      InputProps={{
        endAdornment: (
          <InputAdornment className="inputIconRight" position="end">
            <IconButton aria-label="toggle password visibility" onClick={() => toggleShow()} size="large">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

LinkPassword.defaultProps = {
  autoFocus: false,
};
