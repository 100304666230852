import {
  FuturePayments,
  MandateStatus,
  ManualPaymentProvider,
  OnboardingFlow,
  ProductFlow,
} from '../constants/onboarding';

const CUSTOMER_ACCESS_TOKEN = 'CustomerAccessToken';
const LI_ACCESS_TOKEN = 'LoginIdentityAccessToken';

export function setCustomerAccessToken(token: string): void {
  if (token) localStorage.setItem(CUSTOMER_ACCESS_TOKEN, token);
}

export function getCustomerAccessToken(): string | null {
  return getToken(CUSTOMER_ACCESS_TOKEN);
}

export function setLoginIdentityAccessToken(token: string): void {
  if (token) localStorage.setItem(LI_ACCESS_TOKEN, token);
}

export function getLoginIdentityAccessToken(): string | null {
  return getToken(LI_ACCESS_TOKEN);
}

function getToken(tokenName: string): string | null {
  const token = localStorage.getItem(tokenName);
  if (!token) return null;
  return isExpiredToken(token) ? null : token;
}

export function isExpiredToken(token: string): boolean {
  try {
    const tokenContent = decodeToken(token);
    return tokenContent.exp < Date.now() / 1000;
  } catch (err) {
    return false;
  }
}

// eslint-disable-next-line
export function decodeToken(token: string): Record<string, any> {
  const encodedData = token?.split('.')[1];
  if (encodedData) {
    const buf = Buffer.from(encodedData, 'base64');
    return JSON.parse(buf.toString());
  }
  return {};
}

function isProductFlow(input: string): input is ProductFlow {
  return Object.values(ProductFlow).includes(input as ProductFlow);
}

export function getProductFlow(token: string): ProductFlow {
  try {
    const tokenContent = decodeToken(token);
    // always convert to lower case values
    const prodFlow: string = tokenContent.productFlow?.toLowerCase() ?? '';
    if (isProductFlow(prodFlow)) {
      return prodFlow;
    } else {
      return ProductFlow.Unknown;
    }
  } catch (err) {
    return ProductFlow.Unknown;
  }
}

function isMandateStatus(input: string): input is MandateStatus {
  return Object.values(MandateStatus).includes(input as MandateStatus);
}

export function getMandateStatus(token: string): MandateStatus {
  try {
    const tokenContent = decodeToken(token);
    const mandateStatus: string = tokenContent.mandateStatus ?? '';
    if (isMandateStatus(mandateStatus)) {
      return mandateStatus;
    } else {
      return MandateStatus.UNKNOWN;
    }
  } catch (err) {
    return MandateStatus.UNKNOWN;
  }
}

function isOnboardingFlow(input: string): input is OnboardingFlow {
  return Object.values(OnboardingFlow).includes(input as OnboardingFlow);
}

export function getOnboardingFlow(token: string): OnboardingFlow {
  try {
    const tokenContent = decodeToken(token);
    // always convert to lower case values
    const onboardingFlow: string = tokenContent.onboardingFlow?.toLowerCase() ?? '';
    if (isOnboardingFlow(onboardingFlow)) {
      return onboardingFlow;
    } else {
      return OnboardingFlow.Unknown;
    }
  } catch (err) {
    return OnboardingFlow.Unknown;
  }
}

export function isManualPaymentProvider(input: unknown): input is ManualPaymentProvider {
  return Object.values(ManualPaymentProvider).includes(input as ManualPaymentProvider);
}

export function getManualPaymentProvider(token: string): ManualPaymentProvider {
  try {
    const tokenContent = decodeToken(token);
    const provider: string = tokenContent.manualPaymentProvider ?? '';
    if (isManualPaymentProvider(provider)) {
      return provider;
    } else {
      return ManualPaymentProvider.UNKNOWN;
    }
  } catch (err) {
    return ManualPaymentProvider.UNKNOWN;
  }
}

export function isFuturePayments(input: unknown): input is FuturePayments {
  return Object.values(FuturePayments).includes(input as FuturePayments);
}

export function getFuturePayments(token: string): FuturePayments {
  try {
    const tokenContent = decodeToken(token);
    const futurePayments: string = tokenContent.futurePayments ?? '';
    if (isFuturePayments(futurePayments)) {
      return futurePayments;
    } else {
      return FuturePayments.UNKNOWN;
    }
  } catch (err) {
    return FuturePayments.UNKNOWN;
  }
}
