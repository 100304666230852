import LinkError from '../entities/LinkError';
import amplitude from '../services/amplitude';

export function getCodeFromUrl(urlString: string): string | null {
  const url = new URL(urlString);
  const myRegexp = /\?code=(.*?)&/g;
  const match = myRegexp.exec(url.search);
  if (match) {
    if (match[1] === '') return null;
    return match[1];
  }
  return null;
}

const SERVER_ERROR_MESSAGE = 'Server Error';
const SERVER_ERROR_DETAILS =
  'The authorization server encountered an unexpected condition that prevented it from fulfilling the request.';

export function getErrorResponseFromUrl(urlString: string): LinkError | null {
  const url = new URL(urlString);
  const error = url.searchParams.get('error');
  const errorMessage = url.searchParams.get('error_description') || SERVER_ERROR_MESSAGE;
  const errorDetails = url.searchParams.get('error_details') || SERVER_ERROR_DETAILS;

  if (error === null) return null;

  amplitude.trackLinkingError(error, errorMessage, errorDetails);

  return new LinkError({
    code: 40006,
    type: 'OAUTH_CALLBACK_ERROR',
    message: errorMessage,
    details: errorDetails,
  });
}
