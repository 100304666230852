import { createContext, useCallback, useMemo, useState } from 'react';

import { ActionRequiredData } from '../entities/api';

export type IActionRequiredPagesContext = {
  actionData: Record<string, unknown>;
  pages: ActionRequiredData[];
  setActionData: (actionData: Record<string, unknown>) => void;
  setPages: (pages: ActionRequiredData[]) => void;
};

export const ActionRequiredPagesContext = createContext<IActionRequiredPagesContext>({
  actionData: {},
  pages: [],
  setActionData: () => null,
  setPages: () => null,
});

export const ActionRequiredPagesProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [actionData, setActionData] = useState<Record<string, unknown>>({});
  const [pages, setPages] = useState<ActionRequiredData[]>([]);

  const setPagesWrapper = useCallback((pages: ActionRequiredData[]) => setPages(pages), [setPages]);

  const setActionDataWrapper = useCallback(
    (actionData: Record<string, unknown>) => setActionData(actionData),
    [setActionData],
  );

  const value = useMemo(
    () => ({
      actionData,
      pages,
      setActionData: setActionDataWrapper,
      setPages: setPagesWrapper,
    }),
    [actionData, pages, setActionDataWrapper, setPagesWrapper],
  );

  return <ActionRequiredPagesContext.Provider value={value}>{children}</ActionRequiredPagesContext.Provider>;
};
