import { Dialog, DialogProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    bottom: 0,
    padding: '16px',
    marginBottom: 0,
    width: '100%',
    height: '50%',
  },
});

/**
 * Note: Ideally we would want to directly use the mui Drawer component, however due to
 * the nature of FvLink on desktop (i.e. lots of background), the Drawer behaviour is not
 * as desired.
 *
 * The MUI drawer appears at the bottom of the device screen; i.e. in desktop mode it shows
 * up in the background.
 * To fix this we've defined a custom modal and positioned it to the bottom of
 * the FvScreen (the part of the FvLink foreground)
 */

/**
 * A drawer-style modal that appears at the bottom of the FvLink screen.
 */

export const BottomDrawerModal = ({ classes, style, ...rest }: DialogProps): JSX.Element => {
  const customClasses = useStyles();

  return (
    <Dialog classes={{ paper: customClasses.paper, ...classes }} style={{ position: 'absolute', ...style }} {...rest} />
  );
};
