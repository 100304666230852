import {
  GetMandateAuthLinkRequest,
  LinkToken,
  LinkTokenRequest,
  LoginIdentityAccessToken,
  MandateAuthToken,
} from '../../entities/api/token';
import { MandateRoutes } from '../../routers/routes';
import { translateFvError } from '../../services/errTranslate';
import { isGetMandateLinkAuthLinkRequest, isLinkTokenRequest } from '../../services/runtimeType';
import { removeUndefined } from '../../utils/removeUndefined';

export const SCREEN_TYPE = {
  ERROR: 'error', // i.e. error where we will try to retry
  FVERROR: 'finverse-error',
  SUCCESS: 'success',
  NON_FINAL_SUCCESS: 'non-final-success', // basically success screen, but in standalone mode we will not show the YouCanCloseThisWindowNow
};

export interface MessageState {
  title: string; // Expected to be a translation key, or a valid sentence
  header: string; // Expected to be a translation key, or a valid sentence
  body: string; // Expected to be a translation key, or a valid sentence
  buttonText?: string; // Override button text
  destination?: string; // Override default back behavior, if set it will backstep to first match in history stack
}

interface MessageScreenPath {
  pathname: string;
  search?: string;
  state: MessageState;
}

interface GetMessageScreenPath {
  institutionId: string;
  header: string;
  body: string;
  searchParamsString?: string;
  userState?: string;
  buttonText?: string;
  destination?: string; // Path you want to rewind to
}

type ErrorMessageScreenPath = Omit<GetMessageScreenPath, 'header' | 'body'> & { error: Error };

interface GenericErrorScreenPathParams {
  institutionId: string;
  header: string;
  body: string;
  searchParamsString?: string;
}

/**
 * Build and merge search parameters from records of key and values
 * @param searchParams Record of search parameters, will override searchParamsString on collision
 * @optional searchParamsString existing search parameters in string
 */
export function buildSearchParams(
  searchParams: Record<string, string | undefined | null>,
  searchParamsString?: string,
): string {
  const oldSearchParams = new URLSearchParams(searchParamsString);
  const mergedSearchParams = {
    ...Object.fromEntries(oldSearchParams),
    ...searchParams,
  };
  // Remove undefined
  const cleanedParams = removeUndefined(mergedSearchParams);
  return new URLSearchParams(cleanedParams).toString();
}

export function getSuccessPath(props: GetMessageScreenPath, code = ''): MessageScreenPath {
  const { institutionId, header, body, userState, searchParamsString, buttonText } = props;

  const searchParams = {
    institutionId: institutionId,
    type: SCREEN_TYPE.SUCCESS,
    code: code,
    userState: userState,
  };

  return {
    pathname: '/onboarding/message',
    search: buildSearchParams(searchParams, searchParamsString),
    state: {
      title: 'Success',
      header,
      body,
      buttonText,
    },
  };
}

export function getPaymentLinkErrorPath(props: GenericErrorScreenPathParams): MessageScreenPath {
  const type = SCREEN_TYPE.FVERROR;

  const searchParams = {
    institution_id: props.institutionId,
    type: type,
  };

  return {
    pathname: MandateRoutes.PaymentLinkError,
    search: buildSearchParams(searchParams, props.searchParamsString),
    state: {
      title: 'Error',
      header: props.header,
      body: props.body,
    },
  };
}

export function getErrorPath(props: ErrorMessageScreenPath): MessageScreenPath {
  const { institutionId, error, userState, searchParamsString, destination, buttonText } = props;
  const { message: header, details: body, isFatal: isFvError } = translateFvError(error);
  const type = isFvError ? SCREEN_TYPE.FVERROR : SCREEN_TYPE.ERROR;

  const searchParams = {
    institutionId: institutionId,
    type: type,
    userState: userState,
  };

  return {
    pathname: '/onboarding/message',
    search: buildSearchParams(searchParams, searchParamsString),
    state: {
      title: 'Error',
      header,
      body,
      destination,
      buttonText,
    },
  };
}

export const parseToken = <K extends LinkToken | LoginIdentityAccessToken | MandateAuthToken>(
  token: string,
): K | Record<string, never> => {
  const parts = token.split('.');

  if (parts.length !== 3) {
    return {};
  }

  const stringifiedJson = Buffer.from(parts[1], 'base64').toString(); // base64 -> UTF-8

  try {
    const claims = JSON.parse(stringifiedJson);
    return claims;
  } catch (e) {
    return {}; // Invalid JSON. Should never happen (coz valid JWT) but better to be safe
  }
};

export const parseLinkTokenRequest = (tokenRequest: string): LinkTokenRequest | Record<string, never> => {
  try {
    const request = JSON.parse(tokenRequest);
    if (isLinkTokenRequest(request)) {
      return request;
    }
    return {};
  } catch (e) {
    return {}; // Invalid JSON.
  }
};

export const parseGetMandateAuthLinkRequest = (
  linkRequest: string,
): GetMandateAuthLinkRequest | Record<string, never> => {
  try {
    const request = JSON.parse(linkRequest);
    if (isGetMandateLinkAuthLinkRequest(request)) {
      return request;
    }
    return {};
  } catch (e) {
    return {}; // Invalid JSON.
  }
};

export type AutomaticDataRefreshCheckboxConfig = {
  checked: boolean;
  disabled: boolean;
};

/**
 * Based on the `automaticDataRefresh` claim from the link token, this function
 * provides the config for the checkbox to:
 * -> initialize default state (checked vs. unchecked)
 * -> disable the input from changing if specified as such
 * @param claims The set of claims from parsing the link token as a JWT
 * @returns A config object to setup the "Automatic Data Refresh" checkbox
 */
export const getDataRefreshCheckboxConfig = (claims: Record<string, unknown>): AutomaticDataRefreshCheckboxConfig => {
  if (claims.automaticDataRefresh === 'ON') {
    return {
      checked: true,
      disabled: false,
    };
  }

  if (claims.automaticDataRefresh === 'FORCED_ON') {
    return {
      checked: true,
      disabled: true,
    };
  }

  return {
    checked: false,
    disabled: false,
  };
};

export const getLinkModes = (claims: any): Set<string> => {
  // link_mode should be a space separated string, e.g. "real test"
  if (typeof claims.linkMode !== 'string') {
    return new Set();
  }

  return new Set(claims.linkMode.split(' '));
};

export const getPaymentsSupported = (token: Record<string, string | number>): Set<string> => {
  if (typeof token.paymentsSupported !== 'string') {
    return new Set();
  }

  const paymentsSupported = <string[]>JSON.parse(token.paymentsSupported);
  return new Set(paymentsSupported.map((product) => product.toLowerCase()));
};

export const getInstitutionStatuses = (claims: any): Set<string> => {
  // link_mode should be a space separated string, e.g. "real test"
  if (typeof claims.institutionStatus !== 'string') {
    return new Set();
  }

  return new Set(claims.institutionStatus.split(' '));
};

export const getRealEnabled = (token: Record<string, string | number>): boolean => {
  if (typeof token.realEnabled !== 'string') {
    return false;
  }

  return token.realEnabled === 'true';
};
