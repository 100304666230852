import crypto from 'crypto';

import OpenCrypto from 'opencrypto';

import { Client } from '.';
import { OAUTH_JWK_ERROR } from '../entities';
import LinkError from '../entities/LinkError';
import { EncryptionResult } from '../interfaces/crypto';

export const encryptData = async (client: Client, jwksUrl: string, plainText: string): Promise<EncryptionResult> => {
  const crypt = new OpenCrypto();
  // 0. Get pubkey information from url
  const jwksResponse = await client.getJwks(jwksUrl);

  // If for some reason there is no key
  if (
    Array.isArray(jwksResponse.keys) === false ||
    jwksResponse.keys.length === 0 ||
    jwksResponse.keys[0].x5c.length === 0
  ) {
    throw new LinkError(OAUTH_JWK_ERROR);
  }

  const publicKeyId = jwksResponse.keys[0].kid;
  const publicKeyPem = jwksResponse.keys[0].x5c[0];

  // The padding and oaepHash are as need by KMS to decrpyt with their SDK
  // See https://cloud.google.com/kms/docs/encrypt-decrypt-rsa#kms-encrypt-asymmetric-nodejs
  const options = {
    name: 'RSA-OAEP',
    hash: 'SHA-256',
    usages: ['encrypt'],
  };

  const publicKey = await crypt.pemPublicToCrypto(publicKeyPem, options);

  // 1. Generate AES key, IV
  const aesKey = crypto.randomBytes(32); // 256 bit AES key
  const iv = crypto.randomBytes(16); // 128 bit initialization vector
  const algorithm = 'aes-256-gcm';
  const encryptionCipher = crypto.createCipheriv(algorithm, aesKey, iv);

  // 2. Encrypt the payload / prepare the ciphertext
  const cipherText = encryptionCipher.update(Buffer.from(plainText));
  encryptionCipher.final();
  const authTag = encryptionCipher.getAuthTag(); // For authentication at the decrypting end

  // 3. Encrypt the AES key
  const encryptedAesKey = await crypt.rsaEncrypt(publicKey, aesKey);

  // 4. Return the encryption result
  return {
    keyId: publicKeyId,
    envelopeEncryptionKey: encryptedAesKey.toString('base64'),
    envelope: {
      ciphertext: cipherText.toString('base64'),
      initializationVector: iv.toString('base64'),
      messageAuthenticationCode: authTag.toString('base64'),
    },
  };
};
