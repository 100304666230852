import { MandateStatus } from '../../constants/onboarding';
import { EncryptionResult } from '../../interfaces/crypto';
import { AuthChecklist } from './authChecklist';
import { EmbeddedFvError } from './common';

enum MandateFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum MandateSenderType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

interface TokenInfo {
  access_token: string;
  expires_in: number;
  link_url: string;
  token_type: string;
}

interface MandatePaymentSchedule {
  amount: number;
  frequency: MandateFrequency;
}

interface MandateDetails {
  currency: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  payment_schedule?: MandatePaymentSchedule;
}

export interface MandateRecipient {
  name: string;
}

export type PostMandateAuthRequest = EncryptionResult;

export type MandateAuthResponse = MandateNonFailed | MandateFailed;

type MandateBase = {
  auth_checklist: AuthChecklist;
  encryption_info: { jwks_url: string; key_id: string };
  institution_id: string;
  last_update: string;
  mandate_id: string;
  recipient_account_id: string;
  sender_type: string;
  mandate_details: MandateDetails;
  recipient: MandateRecipient;
};

export type MandateNonFailed = MandateBase & {
  mandate_status: Exclude<MandateStatus, MandateStatus.FAILED>;
};

export type MandateFailed = MandateBase & {
  mandate_status: MandateStatus.FAILED;
  error: EmbeddedFvError;
};

export interface ConfirmPaymentResponse {
  success: boolean;
  skip_autopay_consent: boolean;
}

export interface CreatePaymentLinkMandateRequest {
  sender_type: MandateSenderType;
  payment_attempt_id: string;
}

export interface CreatePaymentLinkMandateResponse {
  mandate_link_token: TokenInfo;
}

export interface CreateFpsTokenResponse {
  fps_token: TokenInfo;
}

export type GetFpsQrCodeResponse = {
  /**
   * base64 representation of QR Code PNG
   */
  qr_code: string;
};

export interface NonSensitivePaymentUserInfo {
  autopay_consent: boolean;
  customer_app_id: string;
  payment_user_id: string;
  autopay_prefill: boolean;
}

export interface SetAutopayConsentRequest {
  autopay_consent: boolean;
}

export interface SenderAccountFvLinkResponse {
  institution_id: string;
  account_number_masked?: string;
}

interface MandateFvLinkResponseBase {
  mandate_id: string;
  institution_id: string;
  mandate_status: string;
  sender_account: SenderAccountFvLinkResponse;
  recipient: MandateRecipient;
}

type MandateFvLinkResponseNonError = MandateFvLinkResponseBase & {
  mandate_status: Exclude<MandateStatus, MandateStatus.FAILED>;
};

type MandateFvLinkResponseError = MandateFvLinkResponseBase & {
  mandate_status: MandateStatus.FAILED;
  error: EmbeddedFvError;
};

export type MandateFvLinkResponse = MandateFvLinkResponseError | MandateFvLinkResponseNonError;
