import { CircularProgress } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const PrimaryButton = styled(Button)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: '800',
  textTransform: 'none',
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  borderRadius: 0,
  boxShadow: '0px -4px 12px 2px rgba(0,0,0,0.3)',
}));
// ^ Typescript workaround. See https://github.com/mui-org/material-ui/issues/29161

export const LoadingButton = (props: ButtonProps & { loading: boolean }) => {
  const { loading, ...other } = props;

  if (loading) {
    return (
      <PrimaryButton {...other} disabled={loading}>
        <CircularProgress />
      </PrimaryButton>
    );
  } else {
    return <PrimaryButton {...other}></PrimaryButton>;
  }
};

// a button that appears to be disabled (but retains other stylistic properties)
const DisabledButton = styled(PrimaryButton)(() => ({
  pointerEvents: 'none',
  cursor: 'default',
  backgroundColor: '#AAA',
  fontSize: '1rem',
  color: '#000',
}));

export const YouCanCloseTheWindowNow = () => {
  const { t } = useTranslation();

  return <DisabledButton variant="contained">{t('closeWindowMsg')}</DisabledButton>;
};
