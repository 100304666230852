/**
 * This is a basic component shared across
 * mandate / cc / fps , which just determines
 * the bottom container based on UIMode
 *
 * iframe -> Continue buttom (and emit window message)
 * others -> Close window text
 */

import { useTranslation } from 'react-i18next';

import { PrimaryButton, YouCanCloseTheWindowNow } from '../../components/Buttons';
import { UIModes } from '../../entities';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';

export const BottomContainerContent = () => {
  const { t } = useTranslation();
  const { redirectToURI } = useRedirectURI();
  const { params } = useSearchParams();
  const { uiMode } = params;

  if (uiMode === UIModes.iframe) {
    return (
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={() => {
          return window.parent.postMessage('success', '*');
        }}
      >
        {t('messageScreenContinue')}
      </PrimaryButton>
    );
  }

  if (uiMode === UIModes.redirect) {
    return (
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={() => {
          redirectToURI();
        }}
      >
        {t('messageScreenContinue')}
      </PrimaryButton>
    );
  }

  // existing default
  return <YouCanCloseTheWindowNow />;
};
