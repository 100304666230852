import { useCallback, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons';
import { MidContainer, TopContainer } from '../../components/Containers';
import LinkHeader from '../../components/LinkHeader';
import { MandateStatus, ProductFlow } from '../../constants/onboarding';
import { UIModes } from '../../entities';
import useAPIClient from '../../hooks/useClient';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { MandateRoutes } from '../../routers/routes';
import { getProductFlow } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { getApiErrorPath } from '../../utils/error_page';
import Screen, { GenericLoadingScreen } from '../common/Screen';
import { BottomContainerContent } from '../payment/BottomContainer';

function SubmittedScreenHeader({
  showEgiroBusinessSreen,
  institutionId,
}: {
  showEgiroBusinessSreen: boolean;
  institutionId: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <LinkHeader institutionId={institutionId} message={t(showEgiroBusinessSreen ? 'processing' : 'success')} alt="" />
  );
}

function SubmittedScreenTopSection({ showEgiroBusinessSreen }: { showEgiroBusinessSreen: boolean }): JSX.Element {
  const { t } = useTranslation();
  return (
    <TopContainer sx={{ gap: 1 }}>
      <div className="centerLargeIcon" style={{ margin: 10 }}>
        {showEgiroBusinessSreen ? <PendingActionsIcon color="primary" /> : <CheckIcon color="primary" />}
      </div>
      <Typography variant="h5" fontWeight="bold">
        {t(
          showEgiroBusinessSreen
            ? 'mandateAuthChecklistSubmittedHeaderForBusinessUser'
            : 'mandateAuthChecklistSubmittedHeader',
        )}
      </Typography>
    </TopContainer>
  );
}

function SubmittedScreenMiddleSection({
  showEgiroBusinessSreen,
  entityName,
}: {
  showEgiroBusinessSreen: boolean;
  entityName: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <MidContainer textAlign="center" sx={{ gap: 1 }}>
      {showEgiroBusinessSreen ? (
        <>
          <Typography gutterBottom>{t('mandateAuthChecklistSubmittedTextDynamicForBusinessUser')}</Typography>
          <Typography gutterBottom>
            {t('mandateAuthChecklistSubmittedTextDynamicForBusinessUserLine2', { label: entityName })}
          </Typography>
          <Typography fontWeight="bold" gutterBottom>
            {t('mandateAuthChecklistSubmittedTextDynamicForBusinessUserLine3')}
          </Typography>
        </>
      ) : (
        <Typography gutterBottom>{t('mandateAuthChecklistSubmittedTextDynamic', { label: entityName })}</Typography>
      )}
    </MidContainer>
  );
}

// PaymentLinkBottomContainer to be used if ProductFlow is PaymentLink
const PaymentLinkBottomContainer = ({ btnText, btnFn }: { btnText: string; btnFn: () => void }) => {
  const { t } = useTranslation();

  const getButtonText = () => {
    if (btnText) {
      return t(btnText, btnText);
    }
  };

  return (
    <PrimaryButton variant="contained" color="primary" onClick={btnFn}>
      {getButtonText()}
    </PrimaryButton>
  );
};

const showEgiroBusinessScreen = (egiroRail: string, senderType: string): boolean => {
  return egiroRail === 'true' && senderType === 'BUSINESS';
};

export default function MandateSubmittedScreen(): JSX.Element {
  const history = useHistory();
  const client = useAPIClient();
  const { params, searchParamsString } = useSearchParams();
  const token = params.token;
  const { setRedirectURI } = useRedirectURI();
  const [loading, setLoading] = useState(true);

  const entityName = typeof params.entity_name === 'string' ? params.entity_name : 'Finverse Technologies Limited';
  const productFlow = getProductFlow(params.token);

  const pollForMandateStatus = useCallback(async () => {
    const expectedMandateStatus: MandateStatus[] = [
      MandateStatus.PROCESSING,
      MandateStatus.READY_TO_SUBMIT,
      MandateStatus.SUBMITTED,
      MandateStatus.SUCCEEDED,
      MandateStatus.FAILED,
    ];
    try {
      let pollMandateStatus: MandateStatus = MandateStatus.UNKNOWN;
      for (let i = 0; i < 90; i++) {
        const pollResp = await client.getMandateAuthList(token);
        if (pollResp === null) {
          throw new Error();
        }
        pollMandateStatus = pollResp.mandate_status;
        if (expectedMandateStatus.includes(pollMandateStatus)) {
          // mandate status is one of the expected status so break the loop
          break;
        }
        await new Promise((r) => setTimeout(r, 1000));
      }
      if (pollMandateStatus === MandateStatus.FAILED) {
        // show the error mandate submission failed screen if mandate has FAILED
        return history.push({
          pathname: MandateRoutes.SubmissionFailedMessage,
          search: searchParamsString,
        });
      }
      setLoading(false);
    } catch (err) {
      // handle generic error
      history.push(getApiErrorPath(err as any, searchParamsString));
    }
  }, [client, token, history, searchParamsString]);

  useEffect(() => {
    if (params.uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [params.uiMode, params.redirect_uri, setRedirectURI]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS);
  }, []);

  useEffect(() => {
    if (productFlow === ProductFlow.PaymentLink) {
      // poll for mandate status only if productFlow is PaymentLink
      pollForMandateStatus();
    } else {
      setLoading(false);
    }
  }, [productFlow, pollForMandateStatus]);

  const onClickNextStep = () => {
    // on click show the payment confirmation screen regardless of the UI mode
    return history.push({
      pathname: MandateRoutes.PaymentConfirm,
      search: searchParamsString,
    });
  };

  if (loading) {
    return <GenericLoadingScreen />;
  }

  const getBottomStickyComponent = (productFlow: ProductFlow): JSX.Element => {
    if (productFlow === ProductFlow.PaymentLink) {
      // for PaymentLink product flow return a specific component that will call onClickNextStep function
      return <PaymentLinkBottomContainer btnFn={onClickNextStep} btnText="Continue" />;
    }
    // for other productFlow no changes
    return <BottomContainerContent />;
  };

  return (
    <Screen
      showCloseButton={params.uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={getBottomStickyComponent(productFlow)}
    >
      <SubmittedScreenHeader
        showEgiroBusinessSreen={showEgiroBusinessScreen(params.egiro_rail, params.sender_type)}
        institutionId={params.institution_id}
      />
      <SubmittedScreenTopSection
        showEgiroBusinessSreen={showEgiroBusinessScreen(params.egiro_rail, params.sender_type)}
      />
      <SubmittedScreenMiddleSection
        showEgiroBusinessSreen={showEgiroBusinessScreen(params.egiro_rail, params.sender_type)}
        entityName={entityName}
      />
    </Screen>
  );
}
