import { ReactElement } from 'react';

import { SxProps, Box } from '@mui/material';

export type ImageProps = {
  src: string;
  alt?: string;
  sx?: SxProps;
};

const Image = (props: ImageProps): ReactElement => (
  <Box
    component="img"
    sx={{
      objectFit: 'contain',
      width: '100%',
      ...props.sx,
    }}
    alt={props.alt}
    src={props.src}
  />
);

export default Image;
