/**
 * This component will drive the rendering of the frontend component (i.e. ConsentScreenFE)
 * This component populates the frontend component on a case-by-case basis
 *
 * Since Consent Screen is fairly static, and does not require hooks, we will render all the necessary logic
 * from within the same higher order component
 */

import { useEffect } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { MandateStatus, OnboardingFlow, ProductFlow } from '../../constants/onboarding';
import useCustomizations from '../../hooks/useCustomizations';
import useSearchParams, { ISearchParams } from '../../hooks/useSearchParams';
import { MandateRoutes, OnboardingRoutes } from '../../routers/routes';
import { decodeToken, getMandateStatus, getOnboardingFlow, getProductFlow } from '../../services';
import { getApiErrorPath } from '../../utils/error_page';
import ConsentScreenJsx, { ConsentScreenItemCardIcon, ConsentScreenItemCardProps } from './ConsentScreenFE';

export const getPostConsentURL = (params: ISearchParams): string => {
  if (typeof params.institution_id === 'string') {
    return OnboardingRoutes.Login(params.institution_id);
  }

  return OnboardingRoutes.Institutions;
};

const getHeaderTextWithCustomization = (t: TFunction, displayName?: string): string => {
  if (displayName !== undefined) {
    return `${displayName} ${t('consentCustomerNotificationHeader')}`;
  } else {
    return t('anonymousNotificationHeader');
  }
};

const getPaymentHeaderText = (t: TFunction, displayName?: string) => {
  if (displayName) {
    return `${t('Pay')} ${displayName}`;
  }

  return t('Pay now');
};

export default function ConsentScreen(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo, error } = useCustomizations();
  const productFlow = getProductFlow(params.token);
  const onboardingFlow = getOnboardingFlow(params.token);
  const mandateStatus = getMandateStatus(params.token);

  // if there is error fetching customizations, return error
  useEffect(() => {
    if (error) {
      history.push(getApiErrorPath(error, location.search));
    }
  }, [error, history]);

  // Items for different flows
  const mandateItems: ConsentScreenItemCardProps[] = [
    {
      title: t('consentFinverseDisclosureSecureHeader'),
      description: t('consentFinverseDisclosureSecureMandatesText'),
      icon: ConsentScreenItemCardIcon.CUSTOM,
      customIconName: 'lockRounded',
    },
    {
      title: t('consentFinverseDisclosureStayInControlHeader'),
      icon: ConsentScreenItemCardIcon.FIREWALL,
      description: t('consentFinverseDisclosureStayInControlPayText'),
    },
    {
      title: t('consentFinverseDisclosureStayInformedHeader'),
      description: t('consentFinverseDisclosureStayInformedText'),
      icon: ConsentScreenItemCardIcon.CUSTOM,
      customIconName: 'notifications',
    },
  ];
  const bankDirectDebitItems: ConsentScreenItemCardProps[] = [
    {
      title: t('consentFinverseDisclosureSecureHeader'),
      icon: ConsentScreenItemCardIcon.CUSTOM,
      customIconName: 'lockRounded',
      description: t('consentFinverseDisclosureSecurePaymentsText'),
    },
    {
      title: t('consentFinverseDisclosureOneTimeAccessHeader'),
      icon: ConsentScreenItemCardIcon.USER_LOCK,
      description: t('consentFinverseDisclosureOneTimeAccessText'),
    },
  ];
  // default flow is data retrieval flow
  const defaultItems: ConsentScreenItemCardProps[] = [
    {
      title: t('consentFinverseDisclosureSecureHeader'),
      icon: ConsentScreenItemCardIcon.CUSTOM,
      customIconName: 'lockRounded',
      description: t('consentFinverseDisclosureSecureText'),
    },
    {
      title: t('consentFinverseDisclosureControlledAccessHeader'),
      icon: ConsentScreenItemCardIcon.FIREWALL,
      description: t('consentFinverseDisclosureControlledAccessText'),
    },
    {
      title: t('consentFinverseDisclosureUnlinkHeader'),
      icon: ConsentScreenItemCardIcon.CUSTOM,
      customIconName: 'securityRounded',
      description: t('consentFinverseDisclosureUnlinkText'),
    },
  ];

  if (productFlow === ProductFlow.PaymentLink || productFlow === ProductFlow.PaymentLinkSetup) {
    // if we have onboarding flow, then user needs to set up payment method
    if (onboardingFlow !== OnboardingFlow.Unknown) {
      return (
        <Redirect
          to={{
            pathname: OnboardingRoutes.SelectPaymentType,
            search: searchParamsString,
          }}
        />
      );
    }

    // if we are in payment link flow and the mandate does not require authorization, we can go directly to the payment pages
    if (mandateStatus !== MandateStatus.AUTHORIZATION_REQUIRED) {
      const paymentConfirmed: string = decodeToken(params.token).paymentConfirmed; // value is "true" or "false" if provided
      const redirectRoute = paymentConfirmed === 'true' ? MandateRoutes.PaymentSubmitted : MandateRoutes.PaymentConfirm;
      return (
        <Redirect
          to={{
            pathname: redirectRoute,
            search: searchParamsString,
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: getPostConsentURL(params),
          search: searchParamsString,
        }}
      />
    );
  }

  if (productFlow === ProductFlow.Mandate) {
    return (
      <ConsentScreenJsx
        header={getPaymentHeaderText(t, customizationInfo.displayName)}
        logoUrl={customizationInfo.logoUrl}
        subTitle={t('mandateConsentFinverseDisclosureHeader')}
        buttonText={t('consentFinverseAgreeTermsButtonText')}
        items={mandateItems}
        buttonClickFn={() => {
          history.push({
            pathname: getPostConsentURL(params),
            search: searchParamsString,
          });
        }}
      />
    );
  }

  if (productFlow === ProductFlow.BankDirectDebit) {
    return (
      <ConsentScreenJsx
        header={getHeaderTextWithCustomization(t, customizationInfo.displayName)}
        logoUrl={customizationInfo.logoUrl}
        subTitle={t('bankDirectDebitFinverseDisclosureHeader')}
        buttonText={t('consentFinverseAgreeTermsButtonText')}
        items={bankDirectDebitItems}
        buttonClickFn={() => {
          history.push({
            pathname: getPostConsentURL(params),
            search: searchParamsString,
          });
        }}
      />
    );
  }

  //default flow is Data Retrieval Flow
  return (
    <ConsentScreenJsx
      header={getHeaderTextWithCustomization(t, customizationInfo.displayName)}
      logoUrl={customizationInfo.logoUrl}
      subTitle={t('consentFinverseDisclosureHeader')}
      buttonText={t('consentFinverseAgreeTermsButtonText')}
      buttonClickFn={() => {
        history.push({
          pathname: getPostConsentURL(params),
          search: searchParamsString,
        });
      }}
      items={defaultItems}
    />
  );
}
