import { ComponentProps, Fragment } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { createTheme, TextField, MenuItem, TextFieldProps, FormControl, FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LinkPassword from '../../components/LinkPassword';
import LinkSelect from '../../components/LinkSelect';
import { LinkStatusField, LinkStatusFieldOption } from '../../entities';
import { useStyles } from '../../pages/common/styles';
import { getTheme } from '../../services/themes';
import SelectInput from '../SelectInput';

type FieldProps = {
  institutionId: string;
  selectClassName?: string;
  errorClassName?: string;
  fields?: LinkStatusField[];
} & (ComponentProps<typeof FormControl> | TextFieldProps);

export const InputFields: React.FC<FieldProps> = ({
  institutionId,
  fields,
  selectClassName,
  errorClassName,
  ...rest
}: FieldProps) => {
  // Themes
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const getSelectInputOptions = (options?: LinkStatusFieldOption[]) =>
    options?.map((option) => (
      <MenuItem id={`${option.value}-item`} key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  if (fields === undefined) return null;
  return (
    <>
      {fields.map(({ name, placeholder, label, type, options, pattern, required = true, helperText }) => {
        // this will load the first translation from the array. If no match found then use the placeholder / label as default
        const componentLabel = t([name, label ?? placeholder], label ?? placeholder);

        switch (type) {
          case 'SELECT': {
            return (
              <Fragment key={name}>
                <ErrorMessage errors={errors} name={name} as="p" className={errorClassName} />
                <LinkSelect
                  id={name}
                  key={name}
                  label={componentLabel}
                  control={control}
                  selectClassName={selectClassName}
                  {...(rest as ComponentProps<typeof FormControl>)}
                >
                  {getSelectInputOptions(options)}
                </LinkSelect>
              </Fragment>
            );
          }
          case 'password': {
            return (
              <Fragment key={name}>
                <LinkPassword
                  id={name}
                  name={name}
                  key={name}
                  label={componentLabel}
                  style={classes.textInput}
                  register={register}
                  autoFocus={true}
                />
                <ErrorMessage errors={errors} name={name} as="p" className={errorClassName} />
              </Fragment>
            );
          }
          case 'SELECT_INPUT': {
            return (
              <SelectInput
                {...(rest as ComponentProps<typeof FormControl> & TextFieldProps)}
                id={name}
                label={componentLabel}
                required={required}
                selectClassName={selectClassName}
                errorClassName={errorClassName}
                textFieldProps={fields.reduce<LinkStatusFieldOption[]>(
                  (acc, field) => (field.options !== undefined ? [...acc, ...field.options] : acc),
                  [],
                )}
                institutionId={institutionId}
                helperText={helperText}
                key={name}
              />
            );
          }
          case 'INPUT':
          case 'text':
          default: {
            return (
              <Fragment key={name}>
                <TextField
                  {...register(name, {
                    ...(pattern && { pattern: { value: pattern.value, message: t(pattern.message) } }),
                    ...(required && {
                      required: { message: t('required', { label: componentLabel }), value: true },
                    }),
                  })}
                  id={name}
                  name={name}
                  key={name}
                  type="text"
                  variant="outlined"
                  label={t(componentLabel)}
                  placeholder={placeholder}
                  InputLabelProps={typeof placeholder === 'string' && placeholder !== '' ? { shrink: true } : {}}
                  error={errors[name]}
                  helperText={errors[name] && errors[name].message}
                  FormHelperTextProps={{ className: errorClassName }}
                  autoFocus={true}
                  inputProps={{ inputMode: pattern?.isNumeric ? 'numeric' : 'text' }}
                  {...(rest as TextFieldProps)}
                />
                {helperText && (
                  <FormHelperText className={classes.selectInputHelperText}>{t(helperText)}</FormHelperText>
                )}
              </Fragment>
            );
          }
        }
      })}
    </>
  );
};
