import { useEffect } from 'react';

import { Error } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MidContainer, TopContainer } from '../../components/Containers';
import LinkHeader from '../../components/LinkHeader';
import { UIModes } from '../../entities';
import useCustomizations from '../../hooks/useCustomizations';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { DisplaySupportMessage } from '../common/components';
import Screen from '../common/Screen';
import { ReferenceIdBox } from '../Onboarding/MessageScreen';
import { BottomContainerContent } from '../payment/BottomContainer';

export default function MandateSubmissionFailedScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const { setRedirectURI } = useRedirectURI();

  useEffect(() => {
    if (params.uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [params.uiMode, params.redirect_uri, setRedirectURI]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.ERROR);
  }, []);

  return (
    <Screen
      showCloseButton={params.uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={<BottomContainerContent />}
    >
      <LinkHeader src={customizationInfo.logoUrl} message={t('Error')} alt="" />
      <TopContainer>
        <div className="centerLargeIcon">
          <Error color="primary" />
        </div>
        <Typography variant="h5" fontWeight="bold">
          Request Failed
        </Typography>
      </TopContainer>
      <MidContainer textAlign="center" sx={{ gap: 1 }}>
        <Typography gutterBottom>Your direct debit authorization request has failed. Please try again.</Typography>
        {DisplaySupportMessage()}
        <ReferenceIdBox institutionId={'finverse'} refernceId={params.mandateId} message="mandateIdMessage" />
      </MidContainer>
    </Screen>
  );
}
