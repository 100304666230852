import { Switch, Route } from 'react-router-dom';

import GenericErrorScreen, { ApiErrorScreen } from '../pages/GenericError';
import { GenericErrorRoutes } from './routes';

export default function ErrorRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={GenericErrorRoutes.ApiError}>
        <ApiErrorScreen />
      </Route>
      <Route path={GenericErrorRoutes.Base}>
        <GenericErrorScreen />
      </Route>
    </Switch>
  );
}
