import { useEffect } from 'react';

import useSearchParams from '../../hooks/useSearchParams';

// Since the signature is base64 encoded, we need to make sure we replace the spaces back with the `+` character
// so that it is correctly URLencoded to `%2B`, so that it can be decoded back to a `+` by the cybersource server
//
// However, for other keys, since they are part of the value which is signed, we need to keep the spaces as is
// so that they are URLencoded to `+` , and then correctly decoded back to a space (` `) by the cybersource server
const getUrlSafeValue = (key: string, value: string) => {
  if (key === 'signature') {
    return value.replace(/\s/g, '+');
  }

  return value;
};

/**
 * Basically this page is an intermediary screen which will take the query params
 * Turn them into form (hidden) inputs, with a form that has the action to cybersource
 * and then auto-trigger the form submission, to start the cybersource checkout session.
 * @returns JSX.Element
 */
export default function CybersourceRedirect(): JSX.Element {
  const s = useSearchParams();

  const inputs: JSX.Element[] = [];
  for (const key of Object.keys(s.params)) {
    // Ignore the action key
    if (key === 'action' || key == 'token' || key == 'uiMode') {
      continue;
    }

    inputs.push(
      <>
        <input type="hidden" name={key} value={getUrlSafeValue(key, s.params[key])}></input>
        <br />
      </>,
    );
  }

  useEffect(() => {
    (document.getElementById('csform') as HTMLFormElement).submit();
  }, []);

  return (
    <>
      <form method="POST" action={s.params.action} id="csform">
        {inputs}
      </form>
    </>
  );
}
