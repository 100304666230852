import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { MandateAuthorizationScreens } from '../../constants/onboarding';
import LinkHeader from '../LinkHeader';

interface HeaderProps {
  institutionId: string;

  // Below used to determine header message
  name?: string;
  type?: string;
  message?: string;
}
export const Header: React.FC<HeaderProps> = ({ institutionId, name, type, message }: HeaderProps) => {
  const { t } = useTranslation();

  const messageKey = useMemo(() => {
    if (message) return message;
    if (name) {
      // Different screen names have headers
      switch (name) {
        case 'SECOND_PASSWORD':
          return 'stepsCredentialsHeader';
        case 'SMS_OTP':
        case 'MOBILE_OTP':
        case 'TOKEN_OTP':
          return 'stepsOtpHeader';
        case 'PUSH_SENT':
          return 'actionRequired';
        case 'DATE_OF_BIRTH':
          return 'stepsDobHeader';
        case 'CONFIRMATION':
          return 'paymentConfirm';
        case 'ACCOUNT_SELECTION':
          return 'selectAccount';
        case MandateAuthorizationScreens.MANDATE_AUTHORIZATION:
          return 'authMandate';
        case MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CONSENT:
          return 'authMandateConsent';
      }
    }
    return `linkStatusType${type}`;
  }, [message, name, type]);

  return <LinkHeader message={t(messageKey, '')} institutionId={institutionId} />;
};
