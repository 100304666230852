import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ClearOutlined from '@mui/icons-material/ClearOutlined';
import { AppBar, IconButton, Toolbar } from '@mui/material';

import useHistoryStack from '../hooks/useHistoryStack';
import LanguageSwitch from './LanguageSwitch';

interface Props {
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onClose?: () => void;
  onBack?: () => void;
}
export default function TopBar({
  showBackButton = false,
  showCloseButton = false,
  onClose,
  onBack,
}: Props): JSX.Element {
  const { historyStack } = useHistoryStack();
  const shouldShowBackButton = showBackButton && historyStack.length > 1;
  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="relative"
      sx={{ maxHeight: 24, backgroundColor: 'background.default' }}
    >
      <Toolbar variant="dense">
        {shouldShowBackButton ? (
          <IconButton edge="start" color="inherit" aria-label="back" onClick={onBack} size="large">
            <ArrowBackIos />
          </IconButton>
        ) : null}
        {historyStack.length === 1 ? <LanguageSwitch /> : null}
        <div style={{ flexGrow: 1 }} />
        {showCloseButton ? (
          <IconButton edge="end" color="inherit" aria-label="close" onClick={onClose} size="large">
            <ClearOutlined />
          </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
