import { ActionRequiredData, CallbackUrl } from '../entities/api';
import { GetMandateAuthLinkRequest, LinkToken, LinkTokenRequest, MandateAuthToken } from '../entities/api/token';
import LinkError from '../entities/LinkError';

export const isLinkError = (obj: any): obj is LinkError =>
  typeof obj === 'object' &&
  obj !== null &&
  (typeof obj.code === 'string' || typeof obj.code === 'number') && // backward-compatible way for new/old error format
  typeof obj.type === 'string' &&
  typeof obj.details === 'string' &&
  typeof obj.message === 'string' &&
  (typeof obj.retry === 'undefined' || typeof obj.retry === 'boolean') &&
  (typeof obj.isFatal === 'undefined' || typeof obj.isFatal === 'string' || typeof obj.isFatal === 'boolean');

export const isLinkStatusActionRequiredData = (obj: any): obj is ActionRequiredData =>
  typeof obj === 'object' && obj !== null && typeof obj.id === 'string';

export const isCallbackUrl = (obj: any): obj is CallbackUrl =>
  typeof obj === 'object' && obj !== null && typeof obj.redirect_uri === 'string';

export const isLinkToken = (obj: any): obj is LinkToken =>
  typeof obj === 'object' && obj !== null && typeof obj.linkTokenRequest === 'string';

export const isMandateAuthToken = (obj: any): obj is MandateAuthToken =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof obj.mandateId === 'string' &&
  typeof obj.getMandateAuthLinkRequest === 'string';

export const isLinkTokenRequest = (obj: any): obj is LinkTokenRequest =>
  typeof obj === 'object' && obj !== null && typeof obj.redirect_uri === 'string' && typeof obj.state === 'string';

export const isGetMandateLinkAuthLinkRequest = (obj: any): obj is GetMandateAuthLinkRequest =>
  typeof obj === 'object' && obj !== null && typeof obj.mandate_id === 'string';
