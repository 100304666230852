import { ComponentProps, useState } from 'react';

import { FormControl, SelectChangeEvent } from '@mui/material';
import { Control } from 'react-hook-form';

import ReactHookFormSelect from './ReactHookForm';

interface Props extends ComponentProps<typeof FormControl> {
  id: string;
  label: string;
  name?: string;
  selectClassName?: string;
  control: Control;
}

const LinkSelect: React.FC<Props> = ({
  id,
  label,
  name,
  control,
  selectClassName,
  children,
  ...rest
}: Props): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    if (typeof event.target.value === 'string') setSelectedValue(event.target.value);
  };

  return (
    <ReactHookFormSelect
      defaultValue={selectedValue}
      label={label}
      id={id}
      name={name ?? id}
      handleChange={handleChange}
      variant="outlined"
      control={control}
      selectClassName={selectClassName}
      {...rest}
    >
      {children}
    </ReactHookFormSelect>
  );
};

export default LinkSelect;
