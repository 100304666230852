import { Dispatch, SetStateAction, useMemo, useState, createContext, ReactNode, useCallback } from 'react';

export interface IRedirectURIContext {
  redirectURI: string;
  setRedirectURI: Dispatch<SetStateAction<string>>;
  redirectToURI: () => void;
}

export const RedirectURIContext = createContext<IRedirectURIContext>({
  redirectURI: '',
  setRedirectURI: () => {
    null;
  },
  redirectToURI: () => {
    null;
  },
});

export const RedirectURIProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [redirectURI, setRedirectURI] = useState<string>('');
  const redirectToURI = useCallback(() => {
    if (typeof window !== 'undefined' && redirectURI !== '') {
      // We do not check if container is an iframe and directly redirect to the uri
      window.location.href = redirectURI;
    }
  }, [redirectURI]);

  const contextValue = useMemo(
    () => ({
      redirectURI,
      setRedirectURI,
      redirectToURI,
    }),
    [redirectToURI, redirectURI],
  );

  return <RedirectURIContext.Provider value={contextValue}>{children}</RedirectURIContext.Provider>;
};
