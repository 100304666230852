import { Switch, Route } from 'react-router-dom';

import SuccessCreditCardScreen from '../pages/payment/card/SuccessCreditCardScreen';
import { CardRoutes } from './routes';

export default function CardRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={CardRoutes.SuccessCreditCard}>
        <SuccessCreditCardScreen />
      </Route>
    </Switch>
  );
}
