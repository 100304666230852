import { EmbeddedFvError } from './common';

export interface GetRedirectUrlToCardProcessorResponse {
  card_processor_redirect_uri: string;
}

export interface RefreshPaymentAttemptResponse {
  redirect_url: string;
}

export enum PaymentStatus {
  AUTHORIZATION_REQURIED = 'AUTHORIZATION_REQUIRED',
  AUTHORIZING = 'AUTHORIZING',
  PROCESSING = 'PROCESSING',
  SUBMITTED = 'SUBMITTED',
  EXECUTED = 'EXECUTED',
  FAILED = 'FAILED',
  REVOKED = 'REVOKED',
  CANCELLED = 'CANCELLED',
}

interface PaymentFvLinkResponseBase {
  payment_id: string;
  status: PaymentStatus;
}

type PaymentFvLinkResponseNonError = PaymentFvLinkResponseBase & {
  status: Exclude<PaymentStatus, PaymentStatus.FAILED>;
};

type PaymentFvLinkResponseError = PaymentFvLinkResponseBase & {
  error: EmbeddedFvError;
  status: PaymentStatus.FAILED;
};

export type PaymentFvLinkResponse = PaymentFvLinkResponseError | PaymentFvLinkResponseNonError;
