import { TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { LinkStatusMessage, LinkStatusMessageType } from '../../entities';
import { JSONTypography, TypographyProp } from '../Typography';

// The name prop can only be a string
// Want to have type to either be a string or function call with the message name provided to it
// Have to omit name otherwise typescript will not compile
interface TextFromMessageTypeProps extends Omit<TypographyProp, 'name'> {
  // Must have type of filter type
  type?: LinkStatusMessageType;
  filterType?: (messageType: LinkStatusMessageType) => boolean;
  messages?: LinkStatusMessage[];
}

const TextFromMessageType: React.FC<TextFromMessageTypeProps> = ({
  messages,
  type,
  filterType,
  ...rest
}: TextFromMessageTypeProps) => {
  const { t } = useTranslation();

  if (messages === undefined) return null;
  return (
    <>
      {messages
        .filter((message) => {
          const { type: messageType } = message;
          if (typeof filterType === 'function') {
            return filterType(messageType);
          }
          return message.type === type;
        })
        .map((message, index) => (
          <JSONTypography key={message.name ?? index} {...message} gutterBottom {...rest}>
            {t(message.name, message.value)}
          </JSONTypography>
        ))}
    </>
  );
};
interface TextProps extends TypographyProps {
  name?: string;
  messages?: LinkStatusMessage[];
}

export const TitleText: React.FC<TextProps> = ({ name, messages, ...rest }: TextProps) => {
  return <TextFromMessageType {...rest} messages={messages} type={LinkStatusMessageType.TITLE} />;
};

interface BodyOrHighlightTextProps extends TypographyProps {
  name?: string;
  messages?: LinkStatusMessage[];
}

export const BodyOrHighlightText: React.FC<BodyOrHighlightTextProps> = ({
  name,
  messages,
  ...rest
}: BodyOrHighlightTextProps) => {
  return (
    <TextFromMessageType
      messages={messages}
      filterType={(messageType) =>
        messageType === LinkStatusMessageType.BODY || messageType === LinkStatusMessageType.HIGHLIGHT
      }
      {...rest}
    />
  );
};

interface FooterTextProps extends TypographyProps {
  name?: string;
  messages?: LinkStatusMessage[];
}

export const FooterText = ({ name, messages, ...rest }: FooterTextProps) => {
  return (
    <TextFromMessageType
      messages={messages}
      filterType={(msgType) => msgType === LinkStatusMessageType.FOOTER}
      {...rest}
    />
  );
};
