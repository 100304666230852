import { useCallback, useEffect, useState } from 'react';

import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { UIModes } from '../../../entities';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { FpsRoutes } from '../../../routers/routes';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import Screen from '../../common/Screen';

export default function SelectAccountHolderNameScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const [accHolderName, setAccHolderName] = useState('');
  const [errorFeedback, setErrorFeedback] = useState(false);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANUAL_PAYMENT_ACCOUNTHOLDER_DETAILS);
  }, []);

  const buttonClickAction = useCallback(
    (name: string) => {
      if (name.trim() === '') {
        setErrorFeedback(true);
        return;
      }

      const newSearchParams = new URLSearchParams(searchParamsString);
      newSearchParams.set('accountHolderName', name);
      history.push({
        pathname: FpsRoutes.PaymentConfirm,
        search: newSearchParams.toString(),
      });
    },
    [searchParamsString, history],
  );

  return (
    <Screen
      showBackButton
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={
        <PrimaryButton
          variant="contained"
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_ACCOUNTHOLDER_DETAILS, 'Confirm account holder name');
            buttonClickAction(accHolderName);
          }}
        >
          {t('messageScreenContinue')}
        </PrimaryButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} alt="Header logo" message="Pay by FPS" />
      <MidContainer sx={{ px: 3, py: 4 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {`${t('Help us identify your payment')}:`}
        </Typography>
        <TextField
          error={errorFeedback}
          value={accHolderName}
          onChange={(e) => setAccHolderName(e.target.value)}
          sx={{ marginTop: 2 }}
          variant="outlined"
          label={t('Account Holder Name')}
          helperText={t('This must match the name registered with your bank')}
        />
      </MidContainer>
    </Screen>
  );
}
