import { TFunction } from 'react-i18next';

export function translateErrorDetails(t: TFunction, raw: string): string {
  // we will split on "(Error details:" because we don't want to translate the technical error details
  const separatorIndex = raw.indexOf('(Error details:');
  if (separatorIndex === -1) {
    // i.e. there is no error details
    return t(raw);
  }

  return `${t(raw.substring(0, separatorIndex).trim())} ${raw.substring(separatorIndex)}`;
}
