import { Switch, Route } from 'react-router-dom';

import PaymentMethodSetupSuccessScreen from '../pages/payment/paymentMethod/PaymentMethodSetupSuccessScreen';
import { PaymentMethodRoutes } from './routes';

export default function PaymentMethodRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={PaymentMethodRoutes.SetupSuccess}>
        <PaymentMethodSetupSuccessScreen />
      </Route>
    </Switch>
  );
}
