import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function DisplaySupportMessage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box m={3}>
      <Typography color="secondary" align="center">
        {t('fvSupportMessage')}
        <SupportEmail />
      </Typography>
    </Box>
  );
}

export const SupportEmail = () => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = 'mailto:support@finverse.com';
        e.preventDefault();
      }}
    >
      {'support@finverse.com'}
    </Link>
  );
};
