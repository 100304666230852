import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import ErrorBoundary from '../components/ErrorBoundary';
// import { supportedLanguage } from '../config';
import { ActionRequiredPagesProvider } from '../contexts/ActionRequiredPagesContext';
import { CLOSE_LINK_UI_ERROR } from '../entities/error';
import useAPIClient from '../hooks/useClient';
import useRedirectURI from '../hooks/useRedirectURI';
import useSearchParams from '../hooks/useSearchParams';
import { parseLinkTokenRequest, parseToken } from '../pages/common/utils';
import ConsentScreen from '../pages/ConsentScreen';
import LoginScreen from '../pages/data/LoginScreen';
import RefreshLandingScreen from '../pages/data/RefreshLandingScreen';
import ActionRequiredScreen from '../pages/Onboarding/ActionRequiredScreen';
import InstitutionsList from '../pages/Onboarding/InstitutionsList';
import MessageScreen from '../pages/Onboarding/MessageScreen';
import SelectSenderTypeScreen from '../pages/payment/bankDirectDebit/SelectSenderTypePage';
import SelectPaymentTypeScreen from '../pages/payment/paymentMethod/SelectPaymentTypeScreen';
import { isLinkToken, isLinkTokenRequest } from '../services/runtimeType';
import { addQueryParamsToUrl } from '../utils/url';
import { OnboardingRoutes } from './routes';

const privateRoute = ['institutions', 'login', 'action'];

export default function OnboardingRouter(): JSX.Element {
  const { params } = useSearchParams();
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { redirectURI, setRedirectURI } = useRedirectURI();

  const parsedToken = useMemo(() => parseToken(params.token), [params.token]);

  const linkTokenRequest = useMemo(() => {
    if (isLinkToken(parsedToken)) {
      return parseLinkTokenRequest(parsedToken.linkTokenRequest);
    }
    return {};
  }, [parsedToken]);

  // We handle all redirection case that affect multi-screen here
  useEffect(() => {
    // goto unauthorized if a user has no token and attempt to access private routes
    if (params.token === '' && privateRoute.some((r) => location.pathname.includes(r))) {
      history.push('/unauthorized');
    }
  }, [history, location.pathname, params.token]);

  // As token exists, we would like to parse the token to get the redirect uri and update the redirect uri
  useEffect(() => {
    if (!isLinkTokenRequest(linkTokenRequest)) {
      return;
    }
    const { state, redirect_uri } = linkTokenRequest;
    if (redirectURI === '' && typeof redirect_uri === 'string') {
      const { type, message } = CLOSE_LINK_UI_ERROR;

      const searchParams = new URLSearchParams({ error: type.toLowerCase(), error_description: t(message), state });
      const redirectURI = addQueryParamsToUrl(redirect_uri, searchParams);
      setRedirectURI(redirectURI);
    }
  }, [linkTokenRequest, redirectURI, setRedirectURI, t]);

  const client = useAPIClient();

  const { data: institutions, error } = useSWRImmutable(
    ['institutions', params.token],
    (_: string, token: string) => client.getInstitutions(token),
    {
      errorRetryCount: 0,
    },
  );

  return (
    <ErrorBoundary>
      <ActionRequiredPagesProvider>
        <Switch>
          <Route exact path={OnboardingRoutes.Consent}>
            <ConsentScreen />
          </Route>
          <Route path={OnboardingRoutes.Institutions}>
            <InstitutionsList institutions={institutions} error={error} />
          </Route>
          {/* this will convert the URL to .../login/:institutionId */}
          <Route path={OnboardingRoutes.Login(':institutionId')}>
            <LoginScreen />
          </Route>
          <Route path={OnboardingRoutes.Message}>
            <MessageScreen />
          </Route>
          {/* this will convert the URL to .../action/:institutionId */}
          <Route path={OnboardingRoutes.ActionReq(':institutionId')}>
            <ActionRequiredScreen />
          </Route>
          <Route path={OnboardingRoutes.SelectPaymentType}>
            <SelectPaymentTypeScreen />
          </Route>
          <Route path={OnboardingRoutes.SelectSenderType}>
            <SelectSenderTypeScreen />
          </Route>
          <Route path={OnboardingRoutes.Refresh}>
            <RefreshLandingScreen />
          </Route>
        </Switch>
      </ActionRequiredPagesProvider>
    </ErrorBoundary>
  );
}
