// Return fullname of a country code, fallback to country code if fullname do not exists
export const toCountryFullname = (abbr: string): string => {
  const mapping: Record<string, string> = {
    hkg: 'Hong Kong',
    sgp: 'Singapore',
    vnm: 'Vietnam',
    phl: 'Philippine',
  };
  return mapping[abbr.toLowerCase()] || abbr;
};
