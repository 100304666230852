import { useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '../../../components/Buttons';
import { BottomDrawerModal } from '../../../components/Modal';
import { ErrorResponse } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useSearchParams from '../../../hooks/useSearchParams';
import { decodeToken } from '../../../services';
import amplitude from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { SupportEmail } from '../../common/components';

interface CancelManualPaymentModalProps {
  open: boolean;
  onClose: () => void;
  currency: string;
  amount: string;
}

export const CancelManualPaymentModal = (props: CancelManualPaymentModalProps) => {
  const { t } = useTranslation();
  const { params, searchParamsString } = useSearchParams();
  const client = useAPIClient();
  const history = useHistory();
  const decodedToken = decodeToken(params.token);
  const retryUrl = decodedToken.retryUrl;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [enableCtaButton, setEnableCtaButton] = useState(false);

  useEffect(() => {
    if (props.open) {
      // if the modal has been opened; enable the button after 0.25 seconds
      setTimeout(() => setEnableCtaButton(true), 250);
    } else {
      // else (i.e. if the modal has been closed) then reset the CTA button state to be disabled
      setEnableCtaButton(false);
    }
  }, [props.open]);

  const cancelPayment = useCallback(async () => {
    try {
      await client.cancelPayment(params.token);
    } catch (e) {
      const err = e as AxiosError<ErrorResponse, null>;
      return history.push(getApiErrorPath(err, searchParamsString));
    }

    window.location.href = retryUrl;
  }, [client, retryUrl, params.token, history, searchParamsString]);

  return (
    <BottomDrawerModal
      open={props.open}
      onClose={props.onClose}
      container={() => document.getElementById('screen-container')}
    >
      <Grid container direction="column" flexDirection="column" spacing={2} flex={1}>
        <Grid container item flex={1} direction="column" spacing={1.5}>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <Grid item flex={1}>
              <Typography color="red" fontWeight="bold" variant="h6" gutterBottom>
                {t('Cancel payment')}: {props.currency} {props.amount}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              <b>{t('If you have not sent your bank transfer:')}</b>{' '}
              {t('continue below to select a different payment method, or to cancel your payment.')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              <b>{t('If you have already sent your bank transfer:')}</b> {t('please email')} <SupportEmail />{' '}
              {t('to check the payment status, or to request a refund.')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={buttonLoading}
            variant="contained"
            fullWidth
            disabled={enableCtaButton === false}
            onClick={() => {
              setButtonLoading(true);
              amplitude.trackButtonClick('CANCEL_MANUAL_PAYMENT_MODAL', 'I have not paid');
              cancelPayment();
            }}
          >
            {t('I have not paid')}
          </LoadingButton>
        </Grid>
      </Grid>
    </BottomDrawerModal>
  );
};
