import { useContext } from 'react';

import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import config from '../config';
import { Product } from '../constants/linkStatusData/products';
import { API_JWKS_URL } from '../constants/url';
import { LoginIdentityContext } from '../contexts/LoginIdentityContext';
import {
  LoginResponse,
  FINVERSE_OAUTH_ERROR,
  OAUTH_CODE_ERROR,
  ActionRequest,
  FRONTEND_CONNECTION_ERROR,
  ActionRequiredData,
  ActionableRedirectData,
  NETWORK_ERROR,
  UNAUTHORIZED_ERROR,
  PaymentConfig,
  REAL_BANK_CONNECTIONS_LIMIT,
  PAYMENT_INSTRUCTION_USED,
} from '../entities';
import { LinkToken, LinkTokenRequest } from '../entities/api/token';
import LinkError from '../entities/LinkError';
import { EncryptionResult } from '../interfaces/crypto';
import { parseToken } from '../pages/common/utils';
import amplitude from '../services/amplitude';
import { encryptData } from '../services/crypto';
import { getCodeFromUrl, getErrorResponseFromUrl } from '../services/http';
import { isLinkError, isLinkStatusActionRequiredData } from '../services/runtimeType';
import { decodeToken } from '../services/token';
import { parseUIMode } from '../utils/stringToEnum';
import useAPIClient from './useClient';
import useRedirectURI from './useRedirectURI';
import useSearchParams from './useSearchParams';

export default function useLoginHelper() {
  const client = useAPIClient();
  const { params } = useSearchParams();
  const { setRedirectURI } = useRedirectURI();
  const { setLoginIdentityId } = useContext(LoginIdentityContext);

  const refresh = params.refresh === 'true';
  const uiMode = parseUIMode(params.uiMode);
  const accessToken: string = params.token ?? '';
  const userState = params.state || '';
  const identifier = params.identifier;
  const linkStatusId = params.linkStatusId;

  // state params
  const { institutionId } = useParams<{ institutionId: string }>();

  const getPaymentConfig = (request: LinkTokenRequest): PaymentConfig | undefined => {
    const productsRequested = request.products_requested;
    const paymentInstructionId = request.payment_instruction_id;
    const userId = request.user_id;
    if (
      Array.isArray(productsRequested) &&
      productsRequested.includes(Product.payments) &&
      paymentInstructionId &&
      userId
    ) {
      return {
        products_requested: productsRequested,
        payment_instruction_id: paymentInstructionId,
        user_id: userId,
      };
    }
    return undefined;
  };

  const handleCommonErrorFlow = (error: unknown): LinkError => {
    // If error is already of type Link Error then forward the error
    if (isLinkError(error)) return error;

    // Since we are now handling error by having a response as 200 and passing the error
    // This should only be called if Finverse Link is sending incorrect data to the backend
    const status = (error as AxiosError).response?.status;
    if (status === 400 || status === 500) {
      return new LinkError(FINVERSE_OAUTH_ERROR);
    } else if (status === 401) {
      return new LinkError(UNAUTHORIZED_ERROR);
    }

    // For anything else we throw NETWORK_ERROR with retry = true
    // This error should not be rendered on the screen and is expected to be retried
    return new LinkError(NETWORK_ERROR);
  };

  const submit = async (inputs: Record<string, string>, storeCredentials: boolean): Promise<LoginResponse | null> => {
    const decoded = parseToken<LinkToken>(params.token);
    const linkTokenRequest = decoded?.linkTokenRequest ? JSON.parse(decoded.linkTokenRequest) : {};
    const req = {
      clientId: config.clientId,
      userId: linkTokenRequest.user_id,
      institutionId,
      redirectUri: config.redirectUri,
      scope: config.oauthScope,
      storeCredentials,
      state: userState,
    };

    const paymentConfig = getPaymentConfig(linkTokenRequest);
    let encryptedData: EncryptionResult;
    try {
      // The JS object to encrypt
      const payload = JSON.stringify({
        ...inputs,
      });
      encryptedData = await encryptData(client, API_JWKS_URL, payload);
    } catch (error) {
      // Not the most elegant way to handle the failed to load crypto library but the underlying library does not expose this info in a clean way. Current error message: "Cannot read property 'importKey' of undefined"
      if (error.message?.indexOf('importKey') > -1) {
        throw new LinkError(FRONTEND_CONNECTION_ERROR);
      }

      throw handleCommonErrorFlow(error);
    }

    try {
      let loginResult: LoginResponse | null;
      if (refresh) {
        // #1 get LIID from token
        const decodedLinkToken = decodeToken(accessToken);
        if (typeof decodedLinkToken.loginIdentityId != 'string') {
          throw new LinkError(UNAUTHORIZED_ERROR);
        }
        loginResult = await client.relink(
          {
            storeCredentials,
            consent: true,
            ...encryptedData,
          },
          decodedLinkToken.loginIdentityId,
          accessToken,
        );
      } else {
        // if not refresh, we will do normal linking flow
        loginResult = await client.login(
          {
            institutionId,
            userId: req.userId,
            consent: true,
            productsRequested: [],
            storeCredentials,
            ...encryptedData,
            paymentConfig,
          },
          accessToken,
        );
      }

      if (!loginResult || !loginResult.linkStatusId) throw new LinkError(OAUTH_CODE_ERROR);
      setLoginIdentityId(loginResult.linkStatusId);
      amplitude.setLoginIdentityId(loginResult.linkStatusId);
      return loginResult;
    } catch (error) {
      const body: any = (error as AxiosError).response?.data;
      if (
        body?.error?.code === REAL_BANK_CONNECTIONS_LIMIT.code ||
        body?.error?.error_code === REAL_BANK_CONNECTIONS_LIMIT.errorCode
      ) {
        throw new LinkError(REAL_BANK_CONNECTIONS_LIMIT);
      }
      // only thrown during link, not relink
      if (body?.error?.message === 'The payment instruction is not in created state') {
        throw new LinkError(PAYMENT_INSTRUCTION_USED);
      }

      throw handleCommonErrorFlow(error);
    }
  };

  const performAction = async (data: Record<string, unknown>): Promise<LoginResponse | null> => {
    // Now we call the login api with the state from previous state
    try {
      // Unable to get identifier from search params
      if (!identifier) throw new LinkError(OAUTH_CODE_ERROR);
      const encryptedData = await encryptData(client, API_JWKS_URL, JSON.stringify(data));
      const finalPayload: ActionRequest = { ...encryptedData, identifier };

      const loginResult = await client.action(finalPayload, linkStatusId, accessToken);
      if (!loginResult) throw new LinkError(OAUTH_CODE_ERROR);
      return loginResult;
    } catch (error) {
      throw handleCommonErrorFlow(error);
    }
  };

  const linkStatus = async (): Promise<ActionableRedirectData | ActionRequiredData> => {
    // Now we call the login api with the state from previous state
    try {
      // Unable to get link status id from search params
      if (!linkStatusId) throw new LinkError(OAUTH_CODE_ERROR);
      const linkStatus = await client.linkStatus(linkStatusId, accessToken, uiMode);

      if (!linkStatus) throw new LinkError(OAUTH_CODE_ERROR);
      if (isLinkStatusActionRequiredData(linkStatus)) return linkStatus;

      // ActionableRedirectData has redirectURL
      const redirectURL = linkStatus.redirectURL;
      setRedirectURI(redirectURL);

      const callbackError = getErrorResponseFromUrl(redirectURL);
      if (callbackError !== null) {
        throw callbackError;
      }

      const code = getCodeFromUrl(redirectURL);
      if (!code) {
        throw new LinkError(OAUTH_CODE_ERROR);
      }
      amplitude.trackLinkingSuccess();
      return { code };
    } catch (error) {
      throw handleCommonErrorFlow(error);
    }
  };

  return { submit, performAction, linkStatus };
}
