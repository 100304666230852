import { ComponentProps } from 'react';

import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface Props extends ComponentProps<typeof FormControl> {
  id: string;
  name: string;
  label: string;
  selectClassName?: string;
  control: Control;
  handleChange: (
    event: SelectChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;
}

const ReactHookFormSelect: React.FC<Props> = ({
  name,
  id,
  label,
  control,
  defaultValue,
  handleChange,
  selectClassName,
  children,
  ...props
}: Props): JSX.Element => (
  <FormControl {...props}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Controller
      render={({ field }) => (
        <Select {...field} labelId={`${id}-label`} label={label} className={selectClassName}>
          {children}
        </Select>
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: `${label} is required` }}
    />
  </FormControl>
);

export default ReactHookFormSelect;
