import { ComponentProps } from 'react';

import { createTheme, Button, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LinkStatusButton, LinkStatusButtonType } from '../../entities';
import { useStyles } from '../../pages/common/styles';
import { getTheme } from '../../services/themes';

type ButtonProps = {
  institutionId: string;
  buttonClassName?: string;
  errorClassName?: string;
  disableButton: boolean;
  buttons?: LinkStatusButton[];
} & ComponentProps<typeof FormControl>;

export const Buttons: React.FC<ButtonProps> = ({
  institutionId,
  buttonClassName,
  buttons,
  disableButton,
}: ButtonProps) => {
  const { t } = useTranslation();
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  if (buttons === undefined) return null;
  return (
    <>
      {buttons.map((button) => {
        let component: JSX.Element;
        switch (button.type) {
          case LinkStatusButtonType.SUBMIT:
            component = (
              <Button
                key={button.name}
                disabled={disableButton}
                fullWidth={true}
                variant="contained"
                color="primary"
                type="submit"
                className={buttonClassName ?? classes.button}
              >
                {t(button.name, button.value)}
              </Button>
            );
            break;
          default:
            component = (
              <Button
                key={button.name}
                disabled={disableButton}
                fullWidth={true}
                variant="contained"
                color="primary"
                type="submit"
                className={buttonClassName ?? classes.button}
              >
                {t(button.name, button.value)}
              </Button>
            );
            break;
        }
        return component;
      })}
    </>
  );
};
