import CardRouter from './card';
import ErrorRouter from './error';
import FpsRouter from './fps';
import MandateRouter from './mandate';
import OnboardingRouter from './onboarding';
import PaymentMethodRouter from './paymentMethod';

export default {
  OnboardingRouter,
  MandateRouter,
  FpsRouter,
  CardRouter,
  PaymentMethodRouter,
  ErrorRouter,
};
